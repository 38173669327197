/* Balloon Tooltips */

[data-balloon] {
  overflow: visible;
}

[data-balloon] {
  cursor: pointer;
  border: 0px none;
  position: relative;
  background: transparent;
  // z-index: 1;

  &::before,
  &::after {
    left: 50%;
    bottom: 100%;
    position: absolute;
    @include opacity(0);
    pointer-events: none;
    z-index: 99999 !important;
    @include transition(all 0.18s ease-out 0.18s);
    @include transform(translate(-50%, 5px));
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

  &::after {
    content: attr(data-balloon);
    @extend .radius-xs;
    @include margin-bottom(10);
    color: $white;
    @extend .fs-12;
    line-height: 1.25rem;
    @include padding(6 12);
    background-color: $tooltip;
    @extend .secondary-font;
    @extend .fw-semibold;
    white-space: nowrap;
  }
}

[data-balloon]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(75, 74, 91, 1)' d='M2.658 0h32.004c-6 0-11.627 12.002-16.002 12.002C14.285 12.002 8.594 0 2.658 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  content: "";
  margin-bottom: 5px;
}

[data-balloon]:hover:before,
[data-balloon][data-balloon-visible]:before,
[data-balloon]:hover:after,
[data-balloon][data-balloon-visible]:after {
  @include opacity(1);
  pointer-events: auto;
  @include transform(translate(-50%, 5px));
}

[data-balloon].font-awesome:after {
  font-family: "FontAwesome";
}

[data-balloon][data-balloon-break]:after {
  white-space: pre-wrap;
}

[data-balloon-pos="down"]:before,
[data-balloon-pos="down"]:after {
  left: 25%;
  top: 100%;
  bottom: auto;
  @include transform(translate(-50%, -10px));
}

[data-balloon-pos="down"]:after {
  margin-top: 11px;
}

[data-balloon-pos="down"]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(75, 74, 91, 1)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}

[data-balloon-pos="down"]:hover:before,
[data-balloon-pos="down"][data-balloon-visible]:before,
[data-balloon-pos="down"]:hover:after,
[data-balloon-pos="down"][data-balloon-visible]:after {
  @include transform(translate(-25%, -5px));
}

[data-balloon-pos="left"]:before,
[data-balloon-pos="left"]:after {
  top: 50%;
  left: auto;
  right: 100%;
  bottom: auto;
  @include transform(translate(10px, -50%));
}

[data-balloon-pos="left"]:after {
  margin-right: 11px;
}

[data-balloon-pos="left"]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(75, 74, 91, 1)' d='M0 33.342V1.338c0 6 12.002 11.627 12.002 16.002C12.002 21.715 0 27.406 0 33.342z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}

[data-balloon-pos="left"]:hover:before,
[data-balloon-pos="left"][data-balloon-visible]:before,
[data-balloon-pos="left"]:hover:after,
[data-balloon-pos="left"][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon-pos="right"]:before,
[data-balloon-pos="right"]:after {
  bottom: auto;
  left: 100%;
  top: 50%;
  @include transform(translate(-10px, -50%));
}

[data-balloon-pos="right"]:after {
  margin-left: 11px;
}

[data-balloon-pos="right"]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(75, 74, 91, 1)' d='M12 2.658v32.004c0-6-12.002-11.627-12.002-16.002C-.002 14.285 12 8.594 12 2.658z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

[data-balloon-pos="right"]:hover:before,
[data-balloon-pos="right"][data-balloon-visible]:before,
[data-balloon-pos="right"]:hover:after,
[data-balloon-pos="right"][data-balloon-visible]:after {
  @include transform(translate(0px, -50%));
}

[data-balloon-length]:after {
  white-space: normal;
}

[data-balloon-length="small"]:after {
  width: 95px !important;
  max-width: 95px !important;
  text-align: center !important;
}
[data-balloon-length="medium"]:after {
  width: 165px;
  text-align: center !important;
}
[data-balloon-length="large"]:after {
  width: 280px;
  text-align: justify !important;
}
[data-balloon-length="xlarge"]:after {
  width: 90vw;
  text-align: left !important;
}

@media screen and (min-width: 768px) {
  [data-balloon-length="xlarge"]:after {
    width: 350px;
  }
}
[data-balloon-length="fit"]:after {
  width: 100%;
}

.chat-list-items {
  &:first-of-type {
    [data-balloon-pos="top"]:before,
    [data-balloon-pos="top"]:after {
      left: 25%;
      top: 100%;
      bottom: auto;
      @include transform(translate(-25%, -10px));
    }

    [data-balloon-pos="top"]:after {
      margin-top: 11px;
    }
    [data-balloon-pos="top"]:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(75, 74, 91, 1)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E");
    }
    [data-balloon-pos="top"]:hover:before,
    [data-balloon-pos="top"][data-balloon-visible]:before,
    [data-balloon-pos="top"]:hover:after,
    [data-balloon-pos="top"][data-balloon-visible]:after {
      @include transform(translate(-25%, -5px));
    }
  }
}

.wallet-address-block {
  [data-balloon] {
    &::before,
    &::after {
      left: calc(50% - 0.125rem) !important;
    }
  }
}

/* End Balloon Tooltips */
