:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

/* Begin links view style */
.u-sf {
  font-family: $secondaryFont;
}

.u-upper {
  text-transform: uppercase;
}

.no-pointer {
  pointer-events: none !important;
}
.all-pointer {
  pointer-events: all !important;
}
.pointer {
  cursor: pointer !important;
}
.not-allowed {
  cursor: not-allowed !important;

  .disabled {
    pointer-events: none !important;
  }
}
.o-hidden {
  overflow: hidden !important;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.full-cover {
  font-size: 0;
  height: 135vh;
  max-height: 1080px;
  display: inherit;

  @media (max-width: 767px) {
    height: 100vh;
    max-height: 650px;
  }
}

@media (min-width: 992px) {
  .ls-25 {
    left: 25% !important;
  }
  .ls-27 {
    left: 27% !important;
  }
}

.size-50,
.size-64,
.size-100 {
  @extend .vc;
}
.size-50 {
  @include size(50px);

  &.light {
    @include padding(10);
    background-color: $white;
  }

  &.dark {
    @include padding(10);
    background-color: $secondary;
    @include box-shadow(inset 0 0 0 1px rgba($greyish, 0.2));
  }
}
.size-48 {
  @include size(48px);
  display: block;
}
.size-60px {
  @include size(60px);
}
.size-64 {
  @include size(64px);
}
.size-100 {
  @include size(100px);
}

.text-red {
  color: $hoverColor !important;
}

.text-left,
.text-start {
  text-align: left !important;
}
.text-right,
.text-end {
  text-align: right !important;
}

.n {
  background-color: rgba($red_sh1, 0.75);
}

.w-100,
.w-full {
  width: 100% !important;
}
.h-100,
.h-full {
  height: 100% !important;
}
.w-20 {
  width: 100%;
  max-width: 20% !important;
}
.w-20 {
  width: 100%;
  max-width: 20% !important;
}
.w-25 {
  width: 100%;
  max-width: 25% !important;
}
.w-40 {
  width: 100%;
  max-width: 40% !important;
}
.w-50 {
  width: 100%;
  max-width: 50% !important;
}
.w-60 {
  width: 100%;
  max-width: 60% !important;
}
.w-65 {
  width: 100%;
  max-width: 65% !important;
}
.w-75 {
  width: 100%;
  max-width: 75% !important;
}
.w-85 {
  width: 100%;
  max-width: 85% !important;
}
.w-auto {
  width: auto !important;
}
@media (max-width: 540px) {
  .sm\:w-85 {
    max-width: 100% !important;
  }
}

.min-100px {
  min-width: 100px;
}
.w-375px {
  width: 100%;
  max-width: 450px;
}

.ml-1,
.ms-1 {
  margin-left: 4px !important;
}
.ms-1b {
  margin-left: 0.5rem !important;
}
.ml-2,
.ms-2 {
  margin-left: 12px !important;
}
.ml-3,
.ms-3 {
  margin-left: 18px !important;
}
.ml-4,
.ms-4 {
  margin-left: 1.5rem !important;
}

.mr-1,
.me-1 {
  margin-right: 4px !important;
}
.me-1b {
  margin-right: 0.5rem !important;
}
.me-2b {
  margin-right: 0.375rem !important;
}
.mr-2,
.me-2 {
  margin-right: 12px !important;
}
.mr-3,
.me-3 {
  margin-right: 18px !important;
}
.me-8,
.mr-8 {
  @include margin-right(8);
}
.mr-20 {
  margin-right: 1.5rem !important;
}
.mr-24,
.me-4 {
  margin-right: 1.5rem !important;
}
.mr-32 {
  margin-right: 2rem !important;
}
.webapp {
  .mr-3 {
    margin-left: 0.313rem !important;
  }
}
.me-10 {
  margin-right: 0.313rem !important; /* 5px */
}

.p-12 {
  @include padding(12);
}
.p-20 {
  @include padding(20);
}
.pt-20 {
  @include padding-top(20);
}
.pr-1,
.pe-1 {
  padding-right: 4px !important;
}
.pr-2,
.pe-2 {
  padding-right: 12px !important;
}
.pr-3,
.pe-3 {
  padding-right: 18px !important;
}

.pl-1,
.ps-1 {
  padding-left: 4px !important;
}
.pl-2,
.ps-2 {
  padding-left: 12px !important;
}
.pl-3,
.ps-3 {
  padding-left: 18px !important;
}

.mx-4px {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.mt-8px {
  margin-top: 0.5rem !important;
}
.mt-32 {
  margin-top: 2rem !important;
}
.pt-32 {
  padding-top: 2rem !important;
}
.pb-32 {
  padding-bottom: 2rem !important;
}
.py-32 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.mb-0.mb-3 {
  margin-bottom: 0 !important;
}
.mb-0\.5 {
  margin-bottom: .125rem !important;
}
.mb-8px {
  margin-bottom: 0.5rem !important;
}
.mb-12px {
  margin-bottom: 0.75rem !important;
}
.mb-20px {
  margin-bottom: 1.25rem !important;
}
.mb-24px {
  margin-bottom: 1.5rem !important;
}
.mb-32 {
  margin-bottom: 2rem !important;
}
.mb-36 {
  margin-bottom: 2.25rem !important;
}
.mb-7 {
  margin-bottom: 70px !important;

  @media (max-width: 767px) {
    margin-bottom: 35px !important;
  }
}
.mb-8 {
  margin-bottom: 80px !important;

  @media (max-width: 767px) {
    margin-bottom: 40px !important;
  }
}
.mb-9 {
  margin-bottom: 90px !important;

  @media (max-width: 767px) {
    margin-bottom: 45px !important;
  }
}
.mb-10 {
  margin-bottom: 100px !important;

  @media (max-width: 767px) {
    margin-bottom: 50px !important;
  }
}
.mb-15 {
  margin-bottom: 150px !important;

  @media (max-width: 767px) {
    margin-bottom: 75px !important;
  }
}

.mt-0\.5 {
  margin-top: .125rem !important;
}

.pr-2 {
  padding-right: 1rem;
}
.pr-3 {
  padding-right: 1.25rem;
}
.w-60 {
  width: 100%;
  max-width: 60% !important;
}
.w-70 {
  width: 100%;
  max-width: 70% !important;
}
.w-20 {
  width: 100%;
  max-width: 20% !important;
}
.w-30 {
  width: 100%;
  max-width: 30% !important;
}

.u-image-covered {
  @include image_background();
}
.u-advertisement-section {
  text-align: center;
  &--m80 {
    margin: 80px 0;
  }
}

.u-hr {
  width: 100%;
  margin: 24px auto;
  @include flexbox;

  hr {
    margin: 0px;
    width: 100%;
    min-height: 1px;
    border: 0px none;
    border-top: 1px solid $headerBorder;
  }
}

.vc {
  @extend .u-flexed;
  @extend .u-align-center;
  @extend .u-justify-center;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-fluid {
    max-width: 100% !important;
  }
}
@media (min-width: 992px) {
  .container,
  .container-fluid {
    max-width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1270px !important;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1374px !important;
  }
}

.col-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.x-close {
  top: 50%;
  right: 0;
  z-index: 50;
  border: none;
  cursor: pointer;
  position: absolute;
  @include size(16px);
  background: transparent;
  @include transform(translateY(-50%));
  @include transition(all 0.4s ease-in-out);
  &:before,
  &:after {
    top: 50%;
    left: -15%;
    right: -15%;
    height: 1px;
    content: " ";
    position: absolute;
    background-color: lighten($black, 20%);
    @include transition(all 0.2s ease-in-out);
  }
  &:before {
    @include transform(rotate(45deg));
  }
  &:after {
    @include transform(rotate(-45deg));
  }
  &:hover {
    /*@include transform(translateY(-50%) rotate(90deg)!important);*/
    &:before,
    &:after {
      background-color: $blue;
    }
  }
}

.bg-offwhite {
  background-color: #e8e7e6;
}

.b-ads-space {
  &.vertical {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }
  }

  &.horizontal {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }

    img,
    iframe,
    object,
    video {
      width: 100%;
      max-width: 769px;
    }
  }
}

/*! Don't Remove it */

.desktop-hidden,
.mobile-hidden {
  display: none !important;
}
@media (min-width: 768px) {
  .desktop-hidden {
    display: inline-flex !important;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .mobile-hidden {
    display: inline-flex !important;
    width: 100%;
  }
  .img-fluid {
    max-width: 100%;
  }

  .mobile-gutter {
    margin-left: -4px !important;
    margin-right: -4px !important;

    > *,
    [class^="col-"] {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}

.input-group {
  .dropdown-toggle {
    &::after {
      border: 0;
      @include size(16px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23b5b7da' d='M0 14V2C0 .9.9 0 2 0h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2z'/%3E%3Cpath fill='%238b8b90' d='M8 10.2c-.2 0-.4-.1-.5-.2L3.9 6.5 5 5.4l3 3 3-3 1.1 1.1L8.5 10c-.1.2-.3.2-.5.2z'/%3E%3C/svg%3E");
      background-size: 16px 16px;
      background-position: center;
      margin-left: 0.75em;
    }
  }
}
.dropdown {
  .dropdown-toggle {
    &::after {
      border: 0;
      @include size(16px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b5b7da'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E");
      background-size: 16px 16px;
      background-position: center;
    }
  }

  &.show {
    .dropdown-toggle {
      &::after {
        // @include transform(rotate(180deg));
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b5b7da'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E");
        background-size: 16px 16px;
        background-position: center;
      }
    }
  }
}

.footer-link {
  .dropdown-toggle {
    &::after {
      background-size: 14px 14px !important;
    }
    &:hover {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231ed760'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E");
      }
    }
  }
}

.common-dropdown {
  .dropdown-toggle {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b5b7da'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E") !important;
    }
  }
}

.footer-dropdown {
  &.show {
    .btn-transparent {
      color: $hoverColor !important;
    }
    .dropdown-toggle {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231ed760'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E");
      }
    }
  }
}

.dropdown-menu {
  border: 0px none;
  @include padding(12);
  @include border-radius(0);
  background-color: $offwhite;
  @include box-shadow(0 2px 4px 1px rgba($black, 0.2));
  border-radius: 0 0 0.25rem 0.25rem;

  &[data-color="dark"] {
    background-color: $border2;

    .dropdown-divider {
      border-top-color: $black_sh1;
      margin-top: 0;
      margin-bottom: 0.75rem;
    }
  }

  .dropdown-item {
    display: block;
    color: $lightergrey;
    @extend .fs-14;
    @extend .normalFont;
    @extend .fw-medium;
    line-height: 20px;
    @include padding(12 14);
    @extend .radius-xs;

    &:hover,
    &:focus,
    &:active {
      color: $success !important;
      background-color: rgba($success, 0.2) !important;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      @extend .dropdown-item;

      &:hover,
      &:focus,
      &:active {
        color: $success !important;
        background-color: rgba($success, 0.2) !important;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 7px auto;
    display: inline-block;
    background-color: rgba($greyish, 0.5);
  }

  &.pull-right {
    right: 0;
    left: auto;
  }

  .network-dropdown {
    .dropdown-item {
      &:hover,
      &:focus,
      &:active {
        color: $white !important;
        background-color: rgba($success, 0.2) !important;
      }
    }
  }
}

.row {
  &.g-3 {
    margin-top: 0 !important;
    margin-left: -6px !important;
    margin-right: -6px !important;

    [class*="col-"] {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
}

.dropdown {
  .btn {
    &:hover,
    &:focus,
    &:active {
      svg {
        fill: $hoverColor !important;
      }
    }
  }
  &.show {
    .btn {
      svg {
        fill: $hoverColor !important;
      }
    }
  }

  .prediction-dropdown {
    .dropdown-item {
      @include margin-bottom(18);
      @extend .easingOut;

      &:hover,
      &:focus,
      &:active,
      &.is-active {
        color: $white;
      }

      &.is-active {
        color: $success_sh1;
      }

      &:last-of-type {
        color: $danger;
        @include margin-bottom(0);
        &:hover,
        &:focus,
        &:active {
          color: darken($red, 10%);
        }
      }
    }

    &.small {
      min-width: 150px;
      max-width: 150px;
    }

    // To Open Dropdown as Bottomsheet style.
    @media (max-width: 767px) {
      top: auto !important;
      left: 15px !important;
      right: 14px !important;
      bottom: 14px !important;
      position: fixed !important;
      max-width: calc(100% - 28px) !important;
      transform: translate3d(0, 0, 0px) !important;
    }
  }
}

.tab-content > .active {
  display: block;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

// Clamp
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.line-clamp-1 {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
  &.line-clamp-2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  &.line-clamp-3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  &.line-clamp-4 {
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
  &.line-clamp-5 {
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
  &.line-clamp-6 {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }
}

.radius {
  &-xxs {
    border-radius: 0.125rem !important;
  }
  &-xs {
    border-radius: 0.25rem !important;
  }
  &-sm {
    border-radius: 0.5rem !important;
  }
  &-md {
    border-radius: 0.75rem !important;
  }
  &-lg {
    border-radius: 1.25rem !important;
  }
  &-xl {
    border-radius: 2.5rem !important;
  }
  &-xxl {
    border-radius: 3.125rem !important;
  }
  &-circle {
    border-radius: 50% !important;
  }
}

.max-w-45 {
  max-width: 45% !important;

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}
.max-w-50 {
  max-width: 50% !important;

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}
.max-w-55 {
  max-width: 55% !important;

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}

.size-8px {
  @include size(8px);
  @extend .vc;
  flex-shrink: 0;

  img,
  svg {
    @include size(8px);
  }
}

.size-10px {
  @include size(10px);
  @extend .vc;
}

.size-12px {
  @include size(12px);
  @extend .vc;
  flex-shrink: 0;

  img,
  svg {
    @include size(12px);
  }
}

.size-14px {
  @include size(14px);
  @extend .vc;

  img,
  svg {
    @include size(14px);
  }
}

.size-16px {
  @include size(16px);
  @include padding(2);
  @extend .vc;

  img,
  svg {
    @include size(12px);
  }

  &.dark {
    background-color: $success_sh1;
  }

  &.light {
    background-color: $white;
  }

  &.p-0 {
    img,
    svg {
      @include size(16px);
    }
  }

  &.p1x {
    @include padding(1);

    img,
    svg {
      @include size(14px);
    }
  }
}

.size-20px {
  @include size(20px);
  @extend .vc;

  img,
  svg {
    @include size(20px);
  }
}

.size-24px {
  @include size(24px);
  @extend .vc;

  &.mr-8 {
    @include margin-right(8);
  }
  &.mr-12 {
    @include margin-right(12);
  }

  image, img {
    @include size(20px);
    @include object-fit(contain);
  }
}

.size-28px {
  @include size(28px);
  @extend .vc;
  flex-shrink: 0;

  &.mr-8 {
    @include margin-right(8);
  }
  &.mr-12 {
    @include margin-right(12);
  }

  &[data-color="success"] {
    color: $success;
    background-color: rgba($success, 0.2);
  }
}

.size-30px {
  @include size(30px);
  @extend .vc;

  img {
    @include size(30px);
    @extend .object-fit-cover;
    @extend .object-position-center;
  }
}
.border-0 {
  border: 0 none !important;
}

.size-32px {
  @include size(32px);
  @include padding(6);
  @extend .vc;

  &.p-0 {
    padding: 0;
  }

  &[data-color="success"] {
    color: $success;
    background-color: rgba($success, 0.2);
  }

  &.dark {
    background: rgba(162, 180, 60, 0.2);
  }

  &.border-1 {
    border: 1px solid $headerBorder;
  }
}
.size-40px {
  @include size(40px);
  @extend .vc;
}

.size-64px {
  @include size(64px);
  @extend .vc;
}

.size-160px {
  @include size(160px);
  @extend .vc;

  img {
    @include size(160px);
  }

  &.qr-code {
    @include padding(8);
    @extend .radius-xs;
    @include linear-gradient((0deg, $border2 0%, $border2 100%), $white);
    @include box-shadow(0 0 0 1px rgba(31, 36, 40, 0.06));

    img {
      @include size(152px);
      @include object-fit(cover);
      aspect-ratio: 1;
    }
  }
}

.border-0 {
  border: 0 none !important;
}
.border-1 {
  border: 1px solid $headerBorder;
}

.min-100vh {
  height: 100vh;

  &-header {
    height: calc(100vh - 65px);

    @media (max-width: 767px) {
      height: calc(100vh - 75px);
    }
  }
}

.object {
  &-fit {
    &-contain {
      @include object-fit(contain !important);
    }
    &-cover {
      @include object-fit(cover !important);
    }
  }
  &-position {
    &-start {
      @include object-position(start !important);
    }
    &-center {
      @include object-position(center !important);
    }
  }
}

// Backdrop
.backdrop {
  &-xs {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  &-sm {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  &-md {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  &-lg {
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
  &-xl {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

.blur {
  -webkit-filter: blur(0);
  filter: blur(0);
  transition: filter 0.4s, -webkit-filter 0.4s;
}
.blur.lazy-load-image-loaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.offcanvas-backdrop {
  &.show {
    opacity: 1 !important;
    background-color: rgba(15,23,42,.5) !important;
    @extend .backdrop-sm;
  }
}

.fw {
  &-normal,
  &-regular {
    font-weight: $weightRegular !important;
  }
  &-medium {
    font-weight: $weightMedium !important;
  }
  &-semibold {
    font-weight: $weightSemibold !important;
  }
  &-bold {
    font-weight: $weightBold !important;
  }
  &-black {
    font-weight: $weightBlack !important;
  }
}

.fs-8 {
  font-size: 0.5rem !important;
}
.fs-9 {
  font-size: 0.563rem !important;
}
.fs-10 {
  font-size: 0.625rem !important;
}
.fs-11 {
  font-size: 0.688rem !important;
}
.fs-12 {
  font-size: 0.75rem !important;
}
.fs-13 {
  font-size: 0.813rem !important;
}
.fs-14 {
  font-size: 0.875rem !important;
}
.fs-15 {
  font-size: 0.938rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-24 {
  font-size: 1.5rem !important;
}
.fs-120px {
  @include font-size(90);
  line-height: 1;

  @include responsive(sm) {
    @include font-size(100);
    line-height: 1;

    @include respond-to(md) {
      @include font-size(110);
      line-height: 1;

      @include respond-to(lg) {
        @include font-size(120);
        line-height: 1;
      }
    }
  }
}

.ls-4 {
  letter-spacing: 0;
  // letter-spacing: -3.6px;
}

.ps-12 {
  padding-left: 0.75rem !important;
}

.mt-40 {
  margin-top: 40px !important;
  @media (max-width: 767px) {
    margin-top: 35px !important;
  }
}
.mt-50 {
  margin-top: 56px !important;
  @media (max-width: 767px) {
    margin-top: 40px !important;
  }
}
.mt-100 {
  margin-top: 100px !important;
  @media (max-width: 767px) {
    margin-top: 75px !important;
  }
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}
.mb-16 {
  margin-bottom: 1rem !important;
}
.mb-20 {
  margin-bottom: 1.25rem !important;
}
.mb-32 {
  margin-bottom: 2rem !important;
}
.mb-50 {
  margin-bottom: 56px !important;
  @media (max-width: 767px) {
    margin-bottom: 40px !important;
  }
}
.mb-70 {
  margin-bottom: 70px !important;
  @media (max-width: 767px) {
    margin-bottom: 55px !important;
  }
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

@media (max-width: 767px) {
  .sm\:mmb-5 {
    margin-bottom: 2.125rem !important;
  }
}

.easingWidth {
  @include transition(width 0.2s);
}

.easingWidthHeight {
  @include transition(width 0.75s, height 0.75s !important);
}

.easing {
  // @include transition(none);
  @include transition(all 300ms ease 0s);
}
.easingOut {
  // @include transition(none);
  @include transition(all 300s ease-in-out);
}

.noWrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
.alert-custom {
  padding: 0.8rem 0.6rem;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .sm\:text-right {
    text-align: right !important;
  }
}

@media (max-width: 992px) {
  .md\:text-center {
    text-align: center !important;
  }
}
/* Begin links view style */

.unclaimed-bonus {
  @media (max-width: 992px) {
    @include flexbox;

    .small-box {
      flex: 1 0 calc(100% / 2 - 10px);
    }
    .button-info {
      margin-left: 20px;
    }
  }

  @media (max-width: 480px) {
    @include flex-direction(column, vertical);

    .button-info {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .sm\:w-30 {
    max-width: 100% !important;
  }
}

.pb-50 {
  @include padding-bottom(15);

  @include responsive(sm) {
    @include padding-bottom(20);

    @include respond-to(md) {
      @include padding-bottom(25);

      @include respond-to(lg) {
        @include padding-bottom(30);

        @include respond-to(xl) {
          @include padding-bottom(35);
        }
      }
    }
  }
}

.py-3 {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}
.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-70 {
  @include padding(40 0);

  @include responsive(sm) {
    @include padding(55 0);

    @include respond-to(md) {
      @include padding(70 0);

      @include respond-to(lg) {
        @include padding(85 0);

        @include respond-to(xl) {
          @include padding(100 0);
        }
      }
    }
  }
}

.primary-gradient {
  background: linear-gradient(
    180deg,
    rgba($primary, 0) 0%,
    rgba($primary, 0.01) 6.67%,
    rgba($primary, 0.04) 13.33%,
    rgba($primary, 0.08) 20%,
    rgba($primary, 0.15) 26.67%,
    rgba($primary, 0.23) 33.33%,
    rgba($primary, 0.33) 40%,
    rgba($primary, 0.44) 46.67%,
    rgba($primary, 0.56) 53.33%,
    rgba($primary, 0.67) 60%,
    rgba($primary, 0.77) 66.67%,
    rgba($primary, 0.85) 73.33%,
    rgba($primary, 0.92) 80%,
    rgba($primary, 0.96) 86.67%,
    rgba($primary, 0.99) 93.33%,
    $primary 100%
  );
}
.secondary-gradient {
  background: linear-gradient(
    180deg,
    rgba($blue_sh6, 0) 0%,
    rgba($blue_sh6, 0.01) 8.17%,
    rgba($blue_sh6, 0.04) 16.33%,
    rgba($blue_sh6, 0.08) 24.5%,
    rgba($blue_sh6, 0.15) 32.67%,
    rgba($blue_sh6, 0.23) 40.83%,
    rgba($blue_sh6, 0.33) 49%,
    rgba($blue_sh6, 0.44) 57.17%,
    rgba($blue_sh6, 0.56) 65.33%,
    rgba($blue_sh6, 0.67) 73.5%,
    rgba($blue_sh6, 0.77) 81.67%,
    rgba($blue_sh6, 0.85) 89.83%,
    rgba($blue_sh6, 0.92) 98%,
    rgba($blue_sh6, 0.96) 106.17%,
    rgba($blue_sh6, 0.99) 114.33%,
    $blue_sh6 122.5%
  );
}

// z Index(s)
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-60 {
  z-index: 60;
}
.z-70 {
  z-index: 70;
}
.z-80 {
  z-index: 80;
}
.z-100 {
  z-index: 100;
}
.z-900 {
  z-index: 900;
}
.z-901 {
  z-index: 901;
}
.z-999 {
  z-index: 999;
}
.z-1000 {
  z-index: 1000;
}

// Top
.top-0 {
  top: 0 !important;
}
.top-0b {
  top: 7px !important;
}
.top-1 {
  top: 0.5rem !important;
}
.top-10 {
  top: 10px !important;
}
.top-12 {
  top: 12px !important;
}
.top-2 {
  top: 20px !important;
}
.top-24 {
  top: 24px !important;
}
.top-3 {
  top: 30px !important;
}
.top-4 {
  top: 40px !important;
}
.top-5 {
  top: 50px !important;
}
.top-1px {
  top: 1px !important;
}
.top-1_px {
  top: 1.5px !important;
}
.top-2px {
  top: 2px !important;
}
.top-3px {
  top: 3px !important;
}
.top-4px {
  top: 4px !important;
}
.top-10px {
  top: 10px !important;
}
.top-15px {
  top: 15px !important;
}
.top_1pxn {
  top: -0.5px !important;
}
.top-1pxn {
  top: -1px !important;
}
.top-2pxn {
  top: -2px !important;
}
.top-3pxn {
  top: -3px !important;
}
.top-4pxn {
  top: -4px !important;
}
.top-5pxn {
  top: -5px !important;
}

// Right
.right-0 {
  right: 0 !important;
}
.right-1b {
  right: 0.25rem !important;
}
.right-1 {
  right: 0.5rem !important;
}
.right-10 {
  right: 10px !important;
}
.right-12 {
  right: 12px !important;
}
.right-16 {
  right: 1rem !important;
}
.right-2 {
  right: 20px !important;
}
.right-24 {
  right: 24px !important;
}
.right-3 {
  right: 30px !important;
}
.right-4 {
  right: 40px !important;
}
.right-5 {
  right: 50px !important;
}
.right-15px {
  right: 15px !important;
}

// Bottom
.bottom-0 {
  bottom: 0 !important;
}
.bottom-1 {
  bottom: 10px !important;
}
.bottom-2 {
  bottom: 20px !important;
}
.bottom-24 {
  bottom: 24px !important;
}
.bottom-3 {
  bottom: 30px !important;
}
.bottom-4 {
  bottom: 40px !important;
}
.bottom-5 {
  bottom: 50px !important;
}

// Left
.left-0 {
  left: 0 !important;
}
.left-1 {
  left: 10px !important;
}
.left-1 {
  left: 10px !important;
}
.left-2 {
  left: 20px !important;
}
.left-3 {
  left: 30px !important;
}
.left-4 {
  left: 40px !important;
}
.left-5 {
  left: 50px !important;
}
.left-20 {
  left: 20px !important;
}

.ty-50 {
  @include transform(translateY(-50%));
}

// Opacity
.opacity-0 {
  @include opacity(0);
}
.opacity-1 {
  @include opacity(0.1);
}
.opacity-2 {
  @include opacity(0.2);
}
.opacity-2 {
  @include opacity(0.2);
}
.opacity-4 {
  @include opacity(0.3);
}
.opacity-5 {
  @include opacity(0.5);
}
.opacity-6 {
  @include opacity(0.6);
}
.opacity-7 {
  @include opacity(0.7);
}
.opacity-8 {
  @include opacity(0.8);
}
.opacity-9 {
  @include opacity(0.9);
}
.opacity-10 {
  @include opacity(1);
}

:root {
  --size: 5px;
}

.someclass {
  position: absolute;
  left: calc(-1 * var(--size));
}

.spring {
  @include transform(scale(0.98) !important);
}

.u-gradient {
  background: linear-gradient(90deg, $info 1.39%, $pink_sh1 33.23%, $yellow 66.1%, $info_sh2 100%);
  background-clip: border-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 2s ease infinite;

  &-rainbow {
    background-image: linear-gradient(89.57deg, $pink_sh1 .75%, $info_sh2 36.66%, $yellow 68.47%, $pink_sh2 99.25%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: gradient 2s ease infinite;
  }
}

.shape-line {
  &-lt,
  &-rt {
    content: "";
    // width: 100%;
    height: 12px;
    position: absolute;
    background-color: $transparent;
    @extend .no-pointer;

    &.data-bg-white {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 144 12' fill='%23FFF'%3E%3Cpath d='M129 0H0v12h144L129 0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: bottom right;
    }
    &.data-bg-pink {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 144 12' fill='%23FB93F4'%3E%3Cpath d='M129 0H0v12h144L129 0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: cover;
    }
  }
  &-lt {
    max-width: 144px;
  }
  &-rt {
    max-width: 35px;
    background-size: cover;
    @include transform(rotate(180deg));
  }
}

.shape-plus {
  z-index: 2;
  @include size(10px);
  position: absolute;
  @extend .u-flexed;
  @extend .u-align-center;
  @extend .u-justify-center;
  @extend .no-pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $white;
  }
  &::before {
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    @include transform(translateY(-50%));
  }
  &::after {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    @include transform(translateX(-50%));
  }

  &.data-bg-white {
    &::before,
    &::after {
      background-color: $white;
    }
  }
  &.data-bg-pink {
    &::before,
    &::after {
      background-color: $red_sh1;
    }
  }
}

// Button
.btn {
  &-square {
    @extend .size-24px;
    outline: none !important;
    border: 0 none !important;
  }
}

// Popover
.popover {
  background-color: $secondary;

  &-header {
    color: $tertiary;
    background-color: $secondary;
    border-bottom-color: $headerBorder;
  }

  &-body {
    color: $tertiary;
  }
}
.bs-popover-auto {
  &[data-popper-placement^="left"] {
    > .popover-arrow {
      &::after {
        border-left-color: $secondary !important;
      }
    }
  }
  &[data-popper-placement^="top"] {
    > .popover-arrow {
      &::after {
        border-top-color: $secondary !important;
      }
    }
  }
  &[data-popper-placement^="bottom"] {
    > .popover-arrow {
      &::after {
        border-bottom-color: $secondary !important;
      }
    }
  }
  &[data-popper-placement^="right"] {
    > .popover-arrow {
      &::after {
        border-right-color: $secondary !important;
      }
    }
  }
}

// Embed Responsive
.embed-responsive {
  padding: 0;
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
  }
  &-1by1 {
    &::before {
      padding-top: 100%;
    }
  }
  &-4by3 {
    &::before {
      padding-top: 75%;
    }
  }
  &-16by9 {
    &::before {
      padding-top: 60.5%;
    }
  }
  &-21by9 {
    &::before {
      padding-top: 42.857143%;
    }
  }

  &-item {
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
    position: absolute;
    @include size(100%);

    > div {
      @include size(100% !important);
    }
  }
}
.video-react {
  &-video {
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
    position: absolute;
    @include size(100%);
  }
}

// Loader Block
.loader-block {
  @include size(100%);
  position: absolute;
  @extend .vc;
  max-height: 100vh;
  // background-color: rgba($white, 0.15);
  z-index: 9 !important;
  @include opacity(1);
}
.moday-body-wrap {
  .modal-body-top {
    min-height: 80px;
  }
}

.forget-bottom-inner {
  .loader-block {
    top: 0;
    left: 0;
  }
}

.fullscreen-loader,
.page-loader {
  @include size(100%);
  position: fixed;
  @extend .vc;
  max-height: 100vh;
  z-index: 199 !important;
  background-color: $black_sh8;

  .loader-block {
    @include opacity(1);
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: fixed;
    background-color: rgba($black_sh8, 1);
    @extend .z-1;
  }
}

@media (min-width: 768px) {
  .page-wrapper {
    .page-loader {
      top: 64px;
      left: 60px;
      width: calc(100% - 60px) !important;
      height: calc(100% - 64px) !important;

      &::before {
        top: 64px;
        left: 60px;
        width: calc(100% - 60px) !important;
        height: calc(100% - 64px) !important;
      }
    }

    &.desktop-menu-enabled {
      .page-loader {
        left: 240px;
        width: calc(100% - 240px) !important;

        &::before {
          left: 240px;
          width: calc(100% - 240px) !important;
        }
      }

      &.chat-enabled {
        .page-loader {
          left: 240px;
          right: 340px;
          width: calc(100% - 580px) !important;

          &::before {
            left: 240px;
            right: 340px;
            width: calc(100% - 580px) !important;
          }
        }
      }
    }

    &.chat-enabled {
      .page-loader {
        left: 60px;
        right: 340px;
        width: calc(100% - 400px) !important;

        &::before {
          left: 60px;
          right: 340px;
          width: calc(100% - 400px) !important;
        }
      }
    }
  }
}

.fullscreen-loader {
  top: 0 !important;
  left: 0 !important;
  z-index: 9999999999 !important;

  .loader-block {
    top: 0 !important;
    left: 0 !important;
  }
}

.section-loader {
  top: 0;
  left: 0;
  @include size(100vw, 100%);
  position: fixed;
  @extend .vc;
  min-height: 100vh;
  background-color: rgba($black_sh8, 1);
  @extend .z-999;

  .page-loader {
    &::before {
      background-color: rgba($black_sh8, 1);
    }
  }

  @media (min-width: 768px) {
    top: 64px;
    left: 60px;
    @include size(calc(100vw - 60px), calc(100% - 64px));
  }
}
.desktop-menu-enabled {
  .section-loader {
    @media (min-width: 768px) {
      top: 64px;
      left: 240px;
      @include size(calc(100vw - 240px), calc(100% - 64px));
    }
  }
  &.chat-enabled {
    .section-loader {
      @media (min-width: 768px) {
        top: 64px;
        left: 240px;
        right: 340px;
        @include size(calc(100vw - 580px), calc(100% - 64px));
      }
    }
  }
}
.chat-enabled {
  .section-loader {
    @media (min-width: 768px) {
      top: 64px;
      left: 60px;
      right: 340px;
      @include size(calc(100vw - 400px), calc(100% - 64px));
    }
  }
}

.sub-loader {
  @include size(100%, 100%);
  position: relative;
  @extend .vc;
  min-height: 280px;
  max-height: 280px;
  background-color: rgba($black, 1);
  @extend .z-999;

  @media (min-width: 768px) {
    min-height: 400px;
    max-height: 400px;
  }

  .page-loader {
    &::before {
      background-color: rgba($black, 1);
    }
  }
}

.search-section {
  .sub-loader {
    background-color: rgba($dropdown, 1);
    @media (min-width: 768px) {
      min-height: calc(400px - 32px);
      max-height: calc(400px - 32px);
    }

    .page-loader {
      &::before {
        background-color: rgba($dropdown, 1);
      }
    }
  }
  .no-data {
    @extend .u-flexed;
    @extend .u-flex-direction-column;
    background-color: $dropdown;
  }
}
.search-section,
.card-detail-list,
.btn-load-more-wrap {
  .spinner-border-sm {
    --bs-spinner-border-width: 0.125em !important;
  }
}

.main-screen {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}
.main-screen2 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: degensScrollUp 0.3s linear;
  animation: degensScrollUp 0.3s linear;
}

.min-h-100,
.min-h-150,
.min-h-200 {
  .loader-block {
    @extend .radius-md;
  }
}

body.search-enabled {
  overflow: hidden !important;

  .main-content {
    .scrollable.scrollY {
      overflow-y: hidden !important;
      // padding-right: 12px;
    }
  }
}

.w-180px {
  max-width: 180px;
}

.min-h-100 {
  min-height: 100px;
}
.min-h-150 {
  min-height: 150px;
}
.min-h-200 {
  min-height: 200px;
}

// Tooltip
.tooltip {
  @extend .no-pointer;

  &-inner {
    color: $white;
    @extend .fs-12;
    line-height: 1.25rem;
    @include padding(6 12);
    background-color: $tooltip;
    @extend .secondary-font;
    @extend .fw-semibold;
    white-space: nowrap;
  }
  &[data-popper-placement^="top"] {
    .tooltip-arrow {
      &::before {
        border-top-width: 0.5rem;
        border-right-width: 0.5rem;
        border-left-width: 0.5rem;
        border-top-color: $tooltip;
      }
    }
  }
  &[data-popper-placement^="right"] {
    .tooltip-arrow {
      &::before {
        border-top-width: 0.5rem;
        border-right-width: 0.675rem;
        border-bottom-width: 0.5rem;
        border-right-color: $tooltip;
      }
    }
  }
  &[data-popper-placement^="bottom"] {
    .tooltip-arrow {
      &::before {
        border-bottom-width: 0.5rem;
        border-right-width: 0.5rem;
        border-left-width: 0.5rem;
        border-bottom-color: $tooltip;
      }
    }
  }
  &[data-popper-placement^="left"] {
    .tooltip-arrow {
      &::before {
        border-top-width: 0.5rem;
        border-left-width: 0.675rem;
        border-bottom-width: 0.5rem;
        border-left-color: $tooltip;
      }
    }
  }
  &.show {
    @include opacity(1);
  }
}
.modal-tooltip {
  @extend .no-pointer;
  z-index: 9999999 !important;

  .tooltip-inner {
    top: -0.125rem;
  }
  .tooltip-arrow {
    top: auto !important;
    left: -2px !important;
    bottom: 0.125rem !important;
  }
}
