/* Layout --> Hero Banner
----------------------------- */
.image-cover {
  width: 100%;
  @extend .object-fit-cover;
  @extend .object-position-center;
}

// Hero Slider
.hero-slider {
  @include margin-top(32);

  .w-220px {
    width: 100%;
    max-width: 220px;

    @media (max-width: 767px) {
      max-width: 302px;
    }
  }
  .hero-content {
    @include padding(32 40);

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      @include padding(24);
      text-align: center;
      @include align-items(center !important);
      @include justify-content(center !important);
      margin: 0 auto;
    }
  }
}

.h-380px,
.hero-slider {
  height: 380px;
  @extend .o-hidden;
  @extend .radius-sm;

  .hero-logo {
    width: 100%;
    max-width: 94px;
    display: block;
    @include margin-bottom(20);
  }

  .hero-title {
    @include font-size(64);
    line-height: 56px;
    @extend .primaryFont;
    @extend .fw-normal;

    @media (max-width: 767px) {
      max-width: 302px;
      font-size: 2.875rem !important;
      line-height: 45px;
    }

    span {
      width: 100%;
      display: inline-block;
    }
  }

  .btn-md,
  .btn-lg {
    height: 48px;
    color: $white;
    @extend .fs-14;
    @extend .primaryFont;
    @extend .fw-bold;
    @include padding(8 20);
    @extend .radius-xs;
    outline: none !important;
    border: 0 none !important;
    box-shadow: none !important;
    @extend .vc;
  }

  .btn-register {
    color: $primary;
  }

  .btn-metamask {
    color: $white;
    position: relative;
    background-color: rgba($white, 0.2);
    backdrop-filter: blur(5px);
  }
  .btn-metamask,
  .btn-register {
    &:hover,
    &:focus {
      color: $hoverColor !important;
      background-color: rgba($hoverColor, 0.2) !important;
      @extend .backdrop-sm;
    }
  }

  .block-or {
    margin-left: auto;
    margin-right: auto;
    color: $tertiary !important;
    @extend .fs-12;
    @extend .normalFont;
    @extend .fw-semibold;

    span {
      background-color: rgba($primary, 0);
    }

    &::before,
    &::after {
      top: 0.656rem;
      height: 1px;
      content: "";
      width: 60px;
      position: absolute;
      background-color: rgba($white, 0.2);
      @extend .no-pointer;
      z-index: -1;
    }
    &::before {
      left: -60px;
    }
    &::after {
      right: -60px;
    }
  }
}

.image-block-wrapper {
  overflow: hidden;
  position: relative;
  padding-bottom: 380px;
  @extend .radius-sm;
  @extend .o-hidden;

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: " ";
    @extend .radius-sm;
    position: absolute;
    @extend .secondary-gradient;
    mix-blend-mode: multiply;
  }

  > span {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    @extend .radius-sm;

    img {
      width: 100% !important;
      height: 380px !important;
      @extend .radius-sm;
    }
  }
}
