/* Begin lists view style */

.list {
    &-group {
        padding-left: 0px !important;
    }
    
    &-unstyled {
        padding-left: 0px !important;
        
        ul {
            @include padding-top(10);
            @include padding-left(35);
        }
    }
    
    &-icon {
        list-style: none;
        @include padding-left(0);
        padding-left: 0px !important;
    }
}

.list-icon {
    $listIconSelf: &;
    
    list-style: none;
    @include padding-left(0);
    padding-left: 0px !important;
    
    li {
        @include margin-left(20);
        @include margin-bottom(10);
        
        &::before {
            top: 5px;
            font-size: 0.75rem; /* 12px */
            position: absolute;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            @include margin-left(-20);
            -webkit-font-smoothing: antialiased;
            font-weight: 900;
        }
    }
    &.list-icon-check,
    &.list-icon-arrow,
    &.list-icon-caret,
    &.list-icon-arrow-circle {
        li {
            &::before {
                font-family: $iconfont;
            }
        }
    }
    
    &.list-icon-check {
        li {
            &::before {
                content: "\f00c";
            }
        }
    }
    
    &.list-icon-arrow {
        li {
            &::before {
                content: "\f054";
            }
        }
    }
    
    &.list-icon-arrow-circle {
        li {
            &::before {
                content: "\f0a9";
            }
        }
    }
    
    &.list-icon-caret {
        li {
            &::before {
                content: "\f0da";
            }
        }
    }
}

.innerpage--content {
    ul, ol {
        @include padding-top(0);
        @include margin-bottom(30);
        
        ul, ol {
            @include padding-top(10);
            @include padding-left(35);
        }
    }
    
    ol[type="1"] {
        padding-left: 30px;
        counter-reset: list;
        
        > li {
            list-style: none;
            position: relative;

            &:before {
                width: 40px;
                left: -40px;
                text-align: right;
                position: absolute;
                padding-right: 10px;
                counter-increment: list;
                content: counter(list) ")";
            }
        }
    }
    
    ol[type="lower-alpha"] {
        padding-left: 30px;
        list-style-type: none;
        
        li {
            counter-increment: section;
            
            &:before {
                width: 40px;
                left: -40px;
                text-align: right;
                position: absolute;
                padding-right: 10px;
                content: counter(section, lower-alpha) ".)";
            }
        }
    }
}

/* Begin lists view style */
