/* Begin Spinner style */

@keyframes spinner {
  to {
    @include transform(rotate(360deg));
  }
}

.spinner,
.spinner2,
.spinner3,
.spinner4,
.spinner5 {
  &::before {
    top: 50%;
    left: 50%;
    content: '';
    margin-top: -23px;
    margin-left: -23px;
    position: absolute;
    @include size(46px);
    box-sizing: border-box;
    @include border-radius(50%);

    @media (max-width: 767px) {
      margin-top: -15px;
      margin-left: -15px;
      @include size(30px);
    }
  }
}

.spinner {
  &::before {
    border: 3px solid $white;
    border-top-color: $hoverColor;
    animation: spinner 0.6s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}
.spinner2 {
  &::before {
    border: 3px solid $white;
    border-top-color: $hoverColor;
    animation: spinner 0.5s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}
.spinner3 {
  &::before {
    border: 3px solid transparent;
    border-top-color: $hoverColor;
    border-bottom-color: $hoverColor;
    animation: spinner 0.8s ease infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}

.spinner4 {
  &::before {
    border-top: 4px solid $hoverColor;
    border-right: 4px solid transparent;
    animation: spinner 0.6s linear infinite;

    @media (max-width: 767px) {
      border-width: 3px;
    }
  }
}

.size-50 {
  .spinner4 {
    &::before {
      border-top: 5px solid $hoverColor;
      border-right: 5px solid transparent;
      animation: spinner 0.6s linear infinite;

      @media (max-width: 767px) {
        border-width: 3px;
      }
    }
  }
}

.spinner5 {
  &::before {
    border: 3px solid $red;
    border-top-color: $success;
    border-right-color: $hoverColor;
    border-bottom-color: $warning;
    animation: spinner 0.5s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}
.size-14 {
  .spinner,
  .spinner2,
  .spinner3,
  .spinner4,
  .spinner5 {
    &::before {
      margin-top: -7px;
      margin-left: -7px;
      @include size(14px);
    }
  }
  .spinner4 {
    &::before {
      border-top: 1.5px solid $white;
      border-right: 1.5px solid transparent;
    }
  }
}

.w-30,
.unclaimed-bonus .button,
.webapp,
.wallet-connect {
  .spinner4 {
    &::before {
      border-top: 2px solid $tertiary;
    }
  }
}
.size-20 {
  @include size(24px);
  @extend .vc;
  .spinner4 {
    @include size(24px);
    @extend .vc;

    &::before {
      margin-top: -12px;
      margin-left: -12px;
      position: absolute;
      @include size(24px);
      border-top: 2px solid $blue_sh4;
    }
  }
}

.ld-ring {
  top: 25px;
  right: 35px;
  z-index: 1;
  position: absolute;
  @include size(2rem);

  &.ld-spin-fast {
    animation: ld-spin-fast 1s infinite linear;
  }

  &::after {
    content: ' ';
    display: block;
    @include size(2rem);
    box-sizing: border-box;
    transform-origin: 0 0;
    backface-visibility: hidden;
    border-radius: 50%;
    border: 0.25rem solid currentColor;
    border-left-color: transparent;
  }
}

@keyframes ld-spin-fast {
  0% {
    animation-timing-function: cubic-bezier(
      0.5856,
      0.0703,
      0.4143,
      0.9297
    );
    transform: rotate(0);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/* End Spinner style */
