/* Begin Home style */
:root {
  --padding: 1.25rem;
  --border-radius: 2rem;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba($white, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba($white, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba($white, 0.075);
}

.link-view {
  color: $white;
  border-bottom: 1px solid currentColor;

  &:hover,
  &:focus,
  &:active {
    color: $hoverColor;
    border-bottom: 1px solid currentColor;
  }
}

.text-link {
  @extend .text-white-50;
  @extend .tertiaryFont;

  &:hover,
  &:focus,
  &:active {
    @extend .text-hover;
  }
}

.c-home {
  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

.c-home,
.c-discover {
  @extend .w-full;
  position: relative;
}

.c-home {
  &__banner {
    padding: 100px 24px 0;
    height: calc(100vh - 90px);
    text-align: center;
    position: relative;
    min-height: 920px;

    @media (max-width: 991px) {
      height: auto;
      padding: 100px 0 0;
      min-height: inherit;
    }

    &__wrap {
      @extend .z-5;
      position: relative;

      @media (max-width: 991px) {
        padding: 0 16px;
      }

      @media (max-width: 575px) {
        text-align: left;
      }
    }
  }

  &__title {
    font-size: 64px;
    line-height: 110%;
    @extend .fw-normal;
    @include margin-bottom(8);
    @extend .text-uppercase;

    @media (max-width: 991px) {
      font-size: 5.5vw;
      line-height: 1.25;
      margin-bottom: 16px;
    }

    @media (max-width: 575px) {
      font-size: 34px;
      text-align: left;
    }

    &__speed {
      position: inherit;
      color: rgba($white, 0.95);
      display: inline-block;
      padding: 32px 48px 32px 0;
      margin-top: -24px;
      background: url(/assets/images/glow.svg) no-repeat;
      background-position-x: 0%;
      background-position-y: 0%;
      background-size: auto;
      background-position: center right;
      background-size: auto 100%;

      @media (max-width: 991px) {
        padding: 3vw 5vw 3vw 0;
      }

      @media (max-width: 575px) {
        padding: 16px 24px 16px 0;
      }
    }
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 18px;
    line-height: 1.5;
    @extend .text-tertiary;
    @extend .fw-medium;

    strong {
      @extend .fw-semibold;
    }
  }

  &__img {
    left: 0;
    right: 0;
    bottom: -4px;
    position: absolute;
    @extend .no-pointer;

    @media (max-width: 991px) {
      position: relative;
      margin-top: 100px;
      bottom: inherit;
      margin-bottom: -5vw;
    }

    @media (max-width: 575px) {
      margin-bottom: -5.5vw;
    }

    &__target {
      left: 0;
      right: 0;
      width: 52px;
      height: 52px;
      min-width: 52px;
      position: absolute;
      margin: auto;
      bottom: 195px;
      animation: crescendo 1.5s alternate infinite ease-in;
      @extend .z-4;

      @media (max-width: 991px) {
        top: 21.3vw;
        bottom: inherit;
      }
    }

    &__bg {
      width: 1600px;
      max-width: none;
      left: -9999px;
      right: -9999px;
      bottom: -300px;
      margin: auto;
      @extend .z-2;
      position: absolute;

      @media (max-width: 991px) {
        top: -32vw;
        width: 170%;
        bottom: inherit;
      }
    }

    &__screen {
      left: 0;
      right: 0;
      max-width: 950px;
      width: 100%;
      margin: auto;
      bottom: -45px;
      z-index: -1;
      position: relative;

      @media (max-width: 991px) {
        bottom: inherit;
        position: relative;
      }
    }

    &__circles {
      width: 1148px;
      max-width: none;
      margin: auto;
      left: -9999px;
      right: -9999px;
      bottom: -354px;
      @extend .z-5;
      position: absolute;

      @media (max-width: 991px) {
        top: -32vw;
        width: 118%;
        bottom: inherit;
      }
    }
  }

  &__line {
    left: 0;
    right: 0;
    @extend .w-full;
    height: 1px;
    z-index: 3;
    margin: auto;
    bottom: 220px;
    position: absolute;
    background-color: rgba($white, 0.1);

    @media (max-width: 991px) {
      top: 25vw;
      bottom: inherit;
    }

    @media (max-width: 575px) {
      top: 27.25vw;
    }

    &__bottom {
      left: 0;
      right: 0;
      bottom: 4px;
      @extend .w-full;
      height: 1px;
      @extend .z-3;
      position: absolute;
      background-color: rgba($white, 0.1);

      @media (max-width: 991px) {
        bottom: inherit;
      }

      @media (max-width: 575px) {
        bottom: 26px;
      }
    }
  }

  &__btn {
    background: linear-gradient($primary, $primary) padding-box,
      linear-gradient(90deg, $info 1.39%, $pink_sh1 33.23%, $yellow 66.1%, $info_sh2 100%) border-box;
    background-size: auto, auto;
    border-radius: 50em;
    border: 3px solid transparent;
    @include box-shadow(0 4px 30px 0 rgba($black, 0.3));
    width: 100%;
    max-width: 250px;
    @include padding(16 40);
    height: 52px;
    font-size: 16px;
    animation: gradient 2s ease infinite;
    background-size: 200% 200%;
  }

  &__checkbox {
    color: $tertiary;
    margin-top: 24px;
    @extend .vc;

    @media (max-width: 575px) {
      text-align: left;
      @include justify-content(flex-start);
    }
  }
}

// Checkbox
.form-check {
  &-label {
    @extend .fs-13;
    @extend .fw-medium;
    @extend .u-inline-flexed;
    @extend .u-align-center;
  }

  &-input {
    margin-top: 0;
    width: 1.125rem;
    height: 1.125rem;
    @include margin-right(10);
    border-color: $tertiary;
    background-color: $tertiary;
    @include border-radius(2px);
    @extend .flex-shrink-0;

    &:checked {
      border-color: $info;
      background-color: $info;

      &[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }
    }

    &:focus {
      @include border-radius(2px);
      @include box-shadow(0 0 0 0.25rem rgba($info, 0.15));
    }
  }
}

.c-home__wins {
  text-align: left;
  position: relative;
  @extend .z-5;
  @extend .secondary-font;
  @extend .fs-12;

  @media (max-width: 991px) {
    background: 0 0;
    backdrop-filter: inherit;
    padding: 0 16px;
    margin: 48px 0;
  }

  @media (min-width: 992px) {
    bottom: 0;
    left: 25px;
    position: absolute;
    padding: 24px 24px 16px;
  }

  &__gain {
    color: $success;
    margin-left: 0.125rem;
  }

  &__item {
    @extend .fs-12;
    @include padding(8 0);
    color: rgba($white, 0.95);
    @extend .fw-medium;
    @extend .text-uppercase;

    &:nth-of-type(3) {
      @include opacity(0.6);
      border-top: 1px solid rgba($white, 0.15);
    }
    &:last-of-type {
      @include opacity(0.3);
      border-top: 1px solid rgba($white, 0.3);
    }
  }

  &__title {
    @extend .fs-14;
    color: rgba($white, 0.95);
    @extend .fw-semibold;
    @extend .text-uppercase;
    margin-left: 0.75rem;
    line-height: 1.5;
  }
}

.c-login__wrapper {
  @extend .w-full;
}

.c-filters {
  @extend .u-flexed;
  @extend .u-align-center;
  grid-gap: 1rem;
  position: relative;
  @extend .z-1;
}

// Discover Page
.c-discover {
  @extend .w-full;
  position: relative;

  &__wrap {
    @extend .z-5;
    position: relative;
    @include padding(16 24 32);

    @media (max-width: 991px) {
      @include padding(16 16 32);
    }
  }

  &__filter {
    color: $white;
    @extend .w-full;
    @extend .u-flexed;
    position: relative;
    margin-left: 0;
    @extend .u-flex-wrap;
  }
}

.c-filter {
  &__dropdown {
    left: 0;
    top: calc(100% + 2px);
    @extend .w-full;
    min-width: 300px;
    max-width: 300px;
    position: absolute;
    border: 1px solid $border2;
    background-color: $primary2;
    @include border-radius(0.75rem);

    &__header,
    &__body,
    &__footer {
      @include padding(20);
    }

    &__body,
    &__footer {
      font-size: 0.813rem;
      border-top: 1px solid $border2;
    }

    &__body {
      &__items {
        @extend .w-full;
        @extend .u-flexed;
        @extend .u-flex-direction-column;
        margin-bottom: 0 !important;
      }

      .hr {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }

      .form-control {
        color: $white;
        box-shadow: none;
        @extend .fs-14;
        @extend .fw-medium;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        border: 1px solid $border2;
        background-color: transparent;
        @include border-radius(20px);
        @extend .easing;

        &:focus {
          border: 1px solid lighten($border2, 5%);
          background-color: rgba($white, 0.05);
        }
      }
    }

    &__footer {
      @extend .u-flexed;
      @extend .u-justify-btw;

      .btn {
        @include border-radius(20px !important);

        &-small {
          @extend .fs-14;
          line-height: 1.35;
          padding: 0.375rem 1.25rem !important;
        }

        &-secendory {
          color: $success !important;
          padding: 0.375rem 0 !important;
          background-color: transparent !important;
        }
      }
    }
  }

  &__checkbox {
    @extend .w-full;
    margin-bottom: 0.25rem;
    @extend .flex-shrink-0;

    .form-check-label {
      color: $white;
    }
  }

  &__divider {
    width: 1px;
    height: 1.25rem;
    background-color: rgba($white, 0.1);
  }

  &__field {
    color: $white;
    @extend .u-flexed;
    @extend .u-align-center;
    @extend .flex-shrink-0;
    min-width: 95px;
    min-height: 34px;
    @include border-radius(20px);
    border: 1px solid $border2;
    background-color: $primary;
    padding: 0.25rem 0.75rem;

    &:hover {
      border: 1px solid lighten($border2, 10%);
    }

    .form-control {
      padding: 0;
      color: $white;
      @extend .fs-14;
      @extend .fw-medium;
      border: 0 none;
      box-shadow: none;
      min-width: 40px;
      max-width: 40px;
      background-color: transparent;
    }
  }
}

// Dashboard
.c-table {
  &__cell {
    @extend .u-flexed;
    @extend .u-align-start;
    @extend .u-flex-direction-column;

    &__wrap {
      @extend .u-flexed;
      @extend .u-align-center;
      @extend .u-flex-direction-row;
    }

    &__subtext {
      @extend .fs-11;
      @include padding-top(2);
    }
  }
}

.c-dashboard {
  @extend .w-full;
  @include padding-top(20);

  &__table {
    @extend .w-full;
    overflow-x: auto;

    .table {
      margin-bottom: 0;
      background-color: transparent;

      thead {
        border-top: 1px solid $border2;
        border-bottom: 1px solid $border2;

        th {
          @extend .u-upper;
          @extend .secondary-font;
          @extend .fw-semibold;
          background-color: $primary;
        }
      }

      thead,
      tbody {
        background-color: transparent;

        th,
        td {
          @extend .fs-13;
          padding: 0.5rem;
          vertical-align: middle;
          @extend .fw-medium;

          &:first-of-type {
            left: 0;
            position: sticky;
            @extend .z-5;
            padding-left: 0.75rem;
            min-width: 250px;
            max-width: 250px;
          }

          &.cell__aligncenter {
            text-align: center;

            .c-table__cell {
              @include align-items(center);
            }
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            text-align: center;

            .c-table__cell {
              @include align-items(center);
            }
          }

          &:last-of-type {
            text-align: right;
            padding-right: 0.75rem;
          }

          @media (max-width: 767px) {
            white-space: nowrap;
            min-width: 200px;

            &:nth-of-type(5),
            &:nth-of-type(6) {
              min-width: inherit !important;
            }
          }
        }

        th {
          color: $tertiary;
        }

        td {
          color: $white;
        }
      }

      tbody {
        tr {
          &.odd {
            td {
              background-color: $black_sh9;
            }
          }
          &.even {
            td {
              background-color: $black_sh10;
            }
          }

          &:hover {
            td {
              background-color: $black_sh11;
            }
          }

          td {
            padding: 0.75rem 0.5rem;

            .u-icon {
              color: $blue_sh7;
            }

            &:last-of-type {
              .c-table {
                &__cell {
                  @extend .u-align-end;
                }
              }
            }
          }
        }
      }
    }
  }

  &__grid {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    transition-delay: 0.5s;

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (min-width: 577px) and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 768px) and (max-width: 1140px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 1141px) and (max-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.c-grid__card {
  border: 1px solid $border2;
  @extend .radius-sm;

  &--image {
    position: relative;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    @extend .o-hidden;
    background-color: $black_sh10;

    &::before {
      content: "";
      display: block;
      padding-top: 70%;

      @media (max-width: 576px) {
        padding-top: 56.25%;
      }
    }

    img {
      inset: 0;
      @include size(100%);
      position: absolute;
      @extend .border-0;
      @include object-fit(cover);
    }
  }

  &--detail {
    height: calc(100% - 55%);
    @include padding(12);
    @extend .u-flexed;
    @extend .u-align-stretch;
    @extend .u-flex-direction-column;
    border-top: 1px solid $border2;

    @media (max-width: 576px) {
      display: block !important;
    }

    &-top,
    &-body,
    &-bottom {
      flex: 0;
      flex-shrink: 0;
    }
    &-body {
      flex: 1 1;
    }
  }
  .c-btn-buy {
    min-height: 32px;
  }
}

.c-table__cell {
  &--thumb {
    @include size(40px);
    @extend .radius-xl;
    @extend .vc;
    background-color: $black_sh8;
    > img {
      border-radius: 50%;
      height: 100%;
    }
  }
  .c-header__social {
    &__item {
      @include size(16px);
      @include margin-left(12);
      overflow: unset !important;

      &:first-of-type {
        margin-left: 0;
      }

      > a {
        color: $offwhite;
        @include size(16px);

        &:hover {
          color: $info !important;
        }
      }
    }
  }
  .name {
    max-width: 190px;

    @media (max-width: 767px) {
      max-width: 175px;
    }
  }

  .description {
    min-height: 40px;
  }
}
.c-header__social__source {
  @include size(16px !important);
  min-width: 16px !important;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  display: inline-flex;
}
.c-header__social__pump {
  border: 1px solid $pump;
  background-image: url(/assets/icons/pump.png);
  box-shadow: 0 0 4px 0 rgba($pump, 0.25);
}
.c-header__social__moonshot {
  border: 1px solid $moonshot;
  background-image: url(/assets/icons/moonshot.svg);
  box-shadow: 0 0 4px 0 rgba($moonshot, 0.25);
  background-color: #000;
}
.c-header__social__raydium {
  @include size(20px !important);
  min-width: 20px !important;
  background-image: url(/assets/icons/raydium.svg);
  background-size: 16px;
}

.c-btn-group {
  @extend .u-flexed;
  @extend .u-align-center;
  background-color: $black_sh1;
  @extend .radius-sm;
  padding: 0.25rem;

  .nav {
    margin: 0;
    @extend .border-0;

    &-item {
      margin: 0 !important;
      @extend .border-0;

      .nav-link {
        color: $white;
        padding: 0.5rem 0.675rem;
        @extend .border-0;
        @extend .vc;
        background-color: $black_sh9;

        svg {
          @include size(16px);
        }

        &.active {
          color: $black;
          background-color: $success;
        }
      }

      &:first-of-type {
        .nav-link {
          border-radius: 0;
          border-top-left-radius: 0.375rem;
          border-bottom-left-radius: 0.375rem;
        }
      }
      &:last-of-type {
        .nav-link {
          border-radius: 0;
          border-top-right-radius: 0.375rem;
          border-bottom-right-radius: 0.375rem;
        }
      }
    }
  }
}

// Keyframes

@keyframes pulse {
  0% {
    @include transform(scale(0.75));
  }
  70% {
    @include transform(scale(1));
    @include box-shadow(0 0 0 12px rgba($success, 0));
  }
  100% {
    @include transform(scale(0.75));
    @include box-shadow(0 0 0 0 rgba($success, 0));
  }
}

@keyframes animation-tY10 {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes crescendo {
  0% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(1.3));
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Toastify */
.Toastify__close-button {
  right: 0.675rem;
  position: absolute;
  @include size(24px);
  top: calc(50% - 15px);
  color: #919095;

  svg {
    @include size(18px);
  }
}
.Toastify__toast-container--top-left {
  top: 88px !important;

  @media (max-width: 767px) {
    top: 80px !important;
    left: 1rem !important;
    right: 1rem !important;
    min-width: calc(100% - 2rem) !important;
    border-radius: 0.425rem !important;
    overflow: hidden;
  }
}

.Toastify__toast {
  $toastifySelf: &;
  border-radius: 0.425rem !important;

  &-icon {
    top: 0.125rem;
    position: relative;
    margin-inline-end: 0.75rem !important;
  }

  &-container {
    padding: 0 !important;
    width: auto !important;
    min-width: 305px !important;
    max-width: 625px !important;
  }

  .toast-message {
    min-height: 24px;
    padding-right: 3.25rem;
  }

  #{$toastifySelf}-body {
    @extend .fs-13;
    line-height: 16px !important;
    @extend .fw-medium;
    @extend .normalFont;
    align-items: center !important;
    text-transform: none !important;
    padding-bottom: 0.75rem !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @extend .fs-16;
      @extend .fw-bold;
      @extend .primaryFont;
      margin-bottom: 0.25rem !important;

      &:last-of-type,
      &:only-of-type {
        margin-bottom: 0;
      }
    }

    p {
      @extend .fs-13;
      @extend .fw-medium;
      line-height: 1.35;
      color: $lightergrey;
      @extend .normalFont;
    }
  }

  #{$toastifySelf}-theme--dark {
    color: $white;
    background: $blue_sh8 !important;
    background-color: $blue_sh8 !important;
  }
  &-theme--dark {
    color: $white;
    background: $blue_sh8;
    background-color: $blue_sh8;
  }

  #{$toastifySelf}-theme--light {
    color: $primary;
  }
  &-theme--light {
    color: $primary;
  }

  > div[role="progressbar"] {
    border-radius: 0;
    margin: 15px 0 0 0;
    max-width: 100%;
  }

  &--success {
    #{$toastifySelf}-icon {
      svg {
        fill: $success;
      }
    }
  }

  &--error {
    #{$toastifySelf}-icon {
      svg {
        fill: $lightergrey;
      }
    }
  }

  @media (max-width: 767px) {
    &-container {
      padding: 0 !important;
    }
  }
}
.Toastify__progress-bar {
  border-radius: 0;

  &--success {
    background-color: $success;
  }

  &--error {
    background-color: $lightergrey;
  }
}

/* Web3modal */
w3m-modal {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 9999 !important;
  position: fixed !important;
  @include size(100% !important);
  background-color: rgba($black, 0.65) !important;
  @extend .backdrop-sm;
}
.web3modal-provider-name,
.web3modal-modal-card {
  font-family: $primaryFont !important;
}
.web3modal-provider-name {
  font-weight: $weightBold !important;
  font-family: $normalFont !important;
}
.web3modal-provider-description {
  font-size: 16px !important;
}

// Responsive
@media (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
  .sm\:d-flex {
    display: flex !important;
  }
  .sm\:w-full {
    width: 100% !important;
  }
  .mobile-col-6,
  .sm\:w-half {
    width: 100% !important;
    max-width: 50% !important;
  }
  .sm\:w-half2 {
    width: 100% !important;
    max-width: 220px !important;
  }
  .mobile-col-4 {
    width: 100% !important;
    max-width: 33.3333% !important;
  }
  .sm\:flex-row {
    @include flex-direction(row, horizontal);
  }
  .sm\:flex-col {
    @include flex-direction(column, vertical);
  }
  .sm\:align-start {
    align-items: flex-start !important;
  }
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-btw {
    justify-content: space-between !important;
  }
  .sm\:mmb-1 {
    margin-bottom: 0.625rem !important;
  }
  .sm\:mmb-2 {
    margin-bottom: 1rem !important;
  }
  .sm\:mmb-20 {
    margin-bottom: 1.25rem !important;
  }
  .sm\:mmb-3 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:mmb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mmb-6 {
    margin-bottom: 3rem !important;
  }
  .sm\:mml-0 {
    margin-left: 0 !important;
  }
  .sm\:mmt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mmt-1 {
    margin-top: 1rem !important;
  }
  .sm\:mmt-5 {
    margin-top: 1.5rem !important;
  }
  .sm\:mmt-6 {
    margin-top: 3.5rem !important;
  }
  .sm\:float-center,
  .sm\:text-center {
    float: none !important;
    text-align: center !important;
    justify-content: center !important;
  }
  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pr-2 {
    padding-right: 40px !important;
  }
  .md\:ml-0px {
    margin-left: 0 !important;
  }
  .md\:nowrap {
    white-space: nowrap;
  }

  .sm-ms-auto {
    margin-left: auto !important;
  }

  .mobile-half {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
    flex-shrink: 0 !important;
  }
  .mobile-third {
    width: 33.33333333% !important;
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
    flex-shrink: 0 !important;
  }
  .mobile-text {
    @include font-size(16);
    line-height: 1.25;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .container {
    max-width: 96% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .md\:flex-col {
    @include flex-direction(column, vertical);
  }
  .md\:align-start {
    align-items: flex-start !important;
  }
  .md\:ml-0px {
    margin-left: 0 !important;
  }
  .md\:nowrap {
    white-space: nowrap;
  }
  .md\:w-half {
    width: 100% !important;
    max-width: 50% !important;
  }
  .md\:w-half2 {
    width: 100% !important;
    max-width: 220px !important;
  }
}

/* End Home style */
