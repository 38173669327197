/* Begin header style(s) */

.c-mask__icon {
  @include size(24px);
  min-width: 24px;
  flex-shrink: 0;
  display: inline-flex;
  background-color: currentColor;
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  &__twitter {
    @include size(20px !important);
    min-width: 20px !important;
    mask-image: url(/assets/icons/Twitter.svg);
    -webkit-mask-image: url(/assets/icons/Twitter.svg);
  }
  &__telegram {
    mask-image: url(/assets/icons/Telegram.svg);
    -webkit-mask-image: url(/assets/icons/Telegram.svg);
  }
  &__wallet {
    mask-image: url(/assets/icons/Telegram.svg);
    -webkit-mask-image: url(/assets/icons/Telegram.svg);
  }
}

.c-header {
  top: 0;
  left: 0;
  right: 0;
  @extend .w-full;
  position: absolute;
  touch-action: none;
  @extend .u-flexed;
  @extend .u-align-center;
  border-bottom: 1px solid rgba($white, 0.1);
  @extend .z-10;

  @media (max-width: 767px) {
    position: fixed;
    z-index: 10 !important;
  }

  &__wrap {
    width: 100%;
    height: var(--header-height);
    @include padding(8 24);
    position: relative;
    @extend .u-flexed;
    @extend .u-align-center;
    align-self: center;
    background-color: rgba($primary2, 0.25);

    @media (max-width: 767px) {
      @include padding(8 16);
    }
  }

  &__left {
    @extend .u-flexed;
    @extend .u-align-center;
  }

  &__toggle {
    margin: 0;
    padding: 0;
    font-size: 13px;
    cursor: pointer;
    line-height: 20px;
    @extend .fw-semibold;
    text-transform: uppercase;
    white-space: nowrap;
    @extend .text-tertiary;
    @extend .secondary-font;
    @include margin-left(15);
  }

  .auth-logo {
    color: $white;
    @include size(150px, 20px);
    @extend .vc;
    @extend .o-hidden !optional;
    transition: 0.2s;
    outline-offset: 0.125rem;

    svg,
    .image-fluid {
      @include size(150px, 20px);
      transition: 0.2s;
      outline-offset: 0.125rem;
    }

    &:active {
      svg {
        transform: scale(0.96);
      }
    }
  }

  &__right {
    @extend .u-flexed;
    @extend .u-align-center;
    position: relative;

    .common-dropdown {
      position: relative;

      .dropdown-toggle {
        padding-right: 40px;
        background-color: transparent !important;

        .w-50px {
          min-width: 48px;
          text-align: left;
        }

        &::after {
          right: 0.75rem;
          position: absolute;
          background-color: transparent !important;
        }
      }

      .dropdown-menu {
        background-color: $primary2;
        border: 1px solid $border2;

        &-wrap {
          .dropdown-item {
            @extend .u-flexed;
            @extend .u-align-center;
          }
        }
      }

      &.show {
        .dropdown-toggle {
          background-color: $primary !important;
        }
      }
    }
  }

  &__social {
    margin: 0;
    padding: 0;
    list-style: none;
    @include margin-left(16);
    @extend .u-flexed;
    @extend .u-align-center;

    &__item {
      margin: 0;
      @include size(28px);
      @extend .vc;
      @extend .o-hidden;

      & + .c-header__social__item {
        @include margin-left(16);
      }

      > a {
        @include size(24px);
        @extend .vc;
      }

      &__icon {
        @include size(24px);
        min-width: 24px;
        color: $tertiary;
        flex-shrink: 0;
        @include transition(all 0.4s ease-in-out);
      }

      &:hover {
        .c-header__social__item__icon {
          color: $white;
        }
      }
    }
  }

  &__wallet {
    margin: 0;
    padding: 0;
    @include size(36px);
    @extend .vc;
    @extend .o-hidden;
    @extend .flex-shrink-0;
    @include margin-left(16);
    background-color: $success;
    @include border-radius(50%);

    &__icon {
      color: $white;
      display: block;
      margin: 0 auto;
      @include size(22px !important);
      min-width: 22px !important;
      mask-image: url(https://photon-sol.tinyastro.io//assets/icons/empty-wallet-b4b5239631520fca3a5b813969ea3dc291a71eb28ad334161d8ba2cbf3482199.svg);
      -webkit-mask-image: url(https://photon-sol.tinyastro.io//assets/icons/empty-wallet-b4b5239631520fca3a5b813969ea3dc291a71eb28ad334161d8ba2cbf3482199.svg);
    }
  }

  &-btn {
    color: $white;
    @include padding(16);
    font-size: 14px;
    @extend .fw-bold;
    line-height: 16px;
    @include margin-left(8);
    background-color: $black_sh1;
    @extend .primaryFont;
    @extend .radius-xs;
    height: 48px;
    max-height: 48px;
    @extend .border-0;

    &:hover,
    &:active,
    &.is-active {
      color: $white;
      background-color: darken($black_sh1, 2%);
    }
    &:active {
      color: $white;
      background-color: lighten($black_sh1, 2%);
      @include transform(scale(0.98) !important);
      * {
        @include transform(scale(0.98) !important);
      }
    }

    .mw-50 {
      min-width: 50px;
      @extend .vc;
    }

    &-wallet,
    &-currency {
      @extend .u-flexed;
      @extend .u-align-center;
    }
    &-wallet {
      color: $primary;
      @include padding-left(20);
      @include padding-right(20);
      background-color: $success;

      .ps-12 {
        padding-left: 10px !important;
        @extend .fw-bold;
      }

      &:hover {
        color: $primary !important;
        background-color: $success_sh3 !important;
      }
      &:active {
        color: $primary !important;
        background-color: $success_sh4 !important;

        @include transform(scale(0.98) !important);
        * {
          @include transform(scale(0.98) !important);
        }
      }
    }

    &-currency {
      color: $white;
      background-color: $primary;

      .number {
        color: $white;
        font-size: 14px;
        @extend .primaryFont;
        @extend .fw-normal;
      }

      .size-16px {
        &.arrow {
          color: $lightergrey;
          @extend .radius-xxs;
          background-color: rgba($white, 0.2);
        }
      }

      &:hover,
      &:active,
      &.is-active {
        color: $white !important;
        background-color: $black_sh1 !important;

        .size-16px {
          &.arrow {
            color: $white;
          }
        }
      }
      &:active {
        @include transform(scale(0.98) !important);
        * {
          @include transform(scale(0.98) !important);
        }
      }
    }

    &-register {
      color: $hoverColor;
      background-color: rgba($hoverColor, 0.2);

      &:hover,
      &.is-active {
        color: $hoverColor;
        background-color: rgba($hoverColor, 0.3);
      }
      &:active {
        color: $hoverColor;
        background-color: rgba($hoverColor, 0.2);

        @include transform(scale(0.98) !important);
        * {
          @include transform(scale(0.98) !important);
        }
      }
    }

    &-profile {
      color: $white;
      background-color: $black_sh1;
      @extend .u-flexed;
      @extend .u-align-center;

      // &:hover,
      // &:active,
      // &.is-active {
      //   color: $hoverColor !important;
      //   background-color: rgba($hoverColor, 0.2) !important;
      // }

      &:hover,
      &.is-active,
      &.is-selected {
        color: $white !important;
        background-color: darken($black_sh1, 2%) !important;

        .mb-1 {
          color: $white !important;
        }
      }
      &:active {
        color: $white !important;
        background-color: lighten($black_sh1, 2%) !important;
        @include transform(scale(0.98) !important);
        * {
          @include transform(scale(0.98) !important);
        }
      }
    }
  }

  &-chat-button {
    color: $white;
    @include padding(6);
    @include size(48px);
    @extend .vc;
    background-color: $black_sh1;
    @extend .radius-xs;

    svg {
      @include size(16px);
    }

    // &:hover,
    // &:active,
    // &.is-active {
    //   color: $hoverColor;
    //   background-color: rgba($hoverColor, 0.2);
    // }

    &:hover,
    &.is-active {
      color: $white !important;
      background-color: darken($black_sh1, 2%) !important;

      svg {
        color: $white !important;
      }
    }
    &:active {
      color: $white !important;
      background-color: lighten($black_sh1, 2%) !important;
      @include transform(scale(0.98) !important);
      * {
        @include transform(scale(0.98) !important);
      }

      svg {
        color: $white !important;
      }
    }
  }

  &-widget {
    height: 20px;
    @include padding(0);
    @include padding-left(24);
    @extend .radius-xl;
    @extend .u-inline-flexed;
    @extend .u-align-center;

    .number {
      font-size: 14px;
      color: $success;
      border: 0 none;
      background-color: transparent;
    }

    .header-settings {
      color: $success;
    }
  }

  &-connect {
    @extend .text-hover;
    @include opacity(1);
    @extend .u-inline-flexed;
    @extend .u-align-center;
    @extend .primaryFont;

    &:hover,
    &:active {
      @extend .text-hover;
      @include opacity(1);
    }
  }

  &-profile {
    .progress {
      max-height: 4px;
      @extend .radius-sm;
      background-color: rgba($white, 0.125);

      &-bar {
        background-color: $white;
        @extend .radius-sm;
        min-width: 5%;
      }
    }

    .header-btn-profile {
      .arrow {
        color: $lightergrey;
        @extend .radius-xxs;
        background-color: rgba($white, 0.2);
      }

      &:hover,
      &:active,
      &.is-active,
      &.is-selected {
        background-color: darken($black_sh1, 2%) !important;

        .mb-1 {
          color: $white;
        }
        .progress {
          background-color: rgba($hoverColor, 0.125);

          &-bar {
            background-color: $hoverColor;
          }
        }
        .arrow {
          color: $white;
        }
      }
      // &.is-selected {
      //   .arrow {
      //     @include transform(rotate(180deg));
      //   }
      // }
    }

    .header-btn-profile,
    .header-btn-wallet {
      &:active {
        @include transform(scale(0.98) !important);
        * {
          @include transform(scale(0.98) !important);
        }
      }
    }
  }

  &.is-sticky {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    @extend .w-full;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  &__navigation {
    .nav {
      &-item {
        margin-bottom: 0;
      }
    }
  }
}

.c-header__link {
  color: $tertiary;
  @extend .fs-14;
  @extend .fw-semibold;
  @extend .u-upper;

  &:hover, &:focus, &:active {
    color: $white;
  }

  &.is-active {
    color: $hoverColor;
  }
}

// OffCanvas
.u-mobile__menu {
  max-width: 300px;
  background-color: $black_sh13;
  @include box-shadow(-8px 0 20px 0 rgba($black, 0.5));

  .navigation {
    @extend .w-full;

    &-list {
      @extend .w-full;
      list-style: none;
      @extend .u-flex-direction-column;

      .c-header__link {
        @extend .fs-16;
      }
    }
  }
  .btn-close {
    margin: 0.5rem;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23b5b7da%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  // .is-user-loggedin {
  .header {
    .auth-logo {
      @include opacity(0);
      visibility: hidden;
      display: none !important;
    }

    .header-profile {
      .arrow,
      &-text {
        display: none !important;
      }
      .size-24px {
        margin-right: 0 !important;
      }
    }
  }
  // }
}

@media screen and (max-width: 1024px) {
  .chat-panel-active {
    .header {
      .auth-logo {
        @include opacity(0);
        visibility: hidden;
        display: none !important;
      }
      .auth-logo-mobile {
        display: flex !important;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1349px) {
  .is-user-loggedin {
    &.chat-enabled {
      .header {
        &-btn-wallet {
          span.ps-12 {
            display: none !important;
          }
        }
        .header-profile {
          .arrow,
          &-text {
            display: none !important;
          }
          .size-24px {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.chat-enabled {
  .header-chat-button {
    display: none !important;
  }
}

.curreny-btns {
  .header-btn {
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.notify-dropdown,
.currency-dropdown,
.profile-dropdown {
  top: 4rem;
  position: absolute;
  @include padding(0);
  background-color: rgba($primary, 0.95);
  @extend .backdrop-xl;
  @extend .radius-sm;
  @extend .z-9;
}
.notify-dropdown {
  right: 0;
  width: 264px;
}

.profile-dropdown {
  right: 0;
  left: auto;
  width: 240px;
  z-index: 9999;
  @include box-shadow(0px 4px 10px 0px rgba($black, 0.25));

  .hr {
    @include margin(16 0);

    hr {
      margin: 0;
      border-color: rgba($white, 0.125);
    }
  }

  .unranked {
    @extend .fs-12;
    line-height: 16px;
    @extend .normalFont;
    @extend .fw-semibold;
    @include margin-bottom(4);
  }

  .profile-details {
    @include padding(16);
    background-color: $black_sh1;
    @extend .radius-xs;
    @include margin-bottom(0);

    .profile-name {
      color: $white;
      font-size: 1rem;
      line-height: 1.5rem;
      @extend .fw-bold;
      @extend .primaryFont;
    }

    &-bottom {
      font-size: 14px;
      @extend .fw-bold;
      @extend .text-tertiary;
    }

    .progress {
      &-bar {
        min-width: 2%;
      }
    }
  }

  .profile-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-items {
      height: 44px;
      @extend .profile-list;
      margin-bottom: 1px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
    &-link {
      height: 44px;
      color: $white;
      @include padding(8);
      @extend .radius-xs;
      @extend .fs-14;
      line-height: 28px;
      font-style: normal;
      @extend .fw-bold;
      @extend .u-flexed;
      @extend .u-align-center;

      > .flex-row {
        height: 28px;
        @extend .u-align-center;
      }

      &:hover,
      &:active,
      &.is-active,
      &.is-selected {
        color: $hoverColor;
        background-color: rgba($hoverColor, 0.2);
      }

      &.logout {
        &:hover,
        &:active,
        &.is-active,
        &.is-selected {
          color: $danger;
          background-color: rgba($danger, 0.2);
        }
      }
    }
  }

  .profile-content {
    height: 100%;
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    @include padding(0 12 12 12);
    @extend .easing;
    overscroll-behavior-y: none !important;
    // max-height: calc(100lvh - 310px);

    @media (max-width: 991px) {
      max-height: calc(100vh - 420px);
    }

    @media screen and (max-device-height: 590px) {
      max-height: calc(100vh - 420px);
    }

    @media screen and (min-device-height: 591px) {
      max-height: calc(100vh - 300px);
    }

    &-wrap {
      @extend .w-100;
      @include padding-right(0);
    }
  }
}

// Currency Dropdown
.currency-dropdown {
  left: 0;
  right: right;
  width: 220px;

  &-header,
  &-footer,
  &-body {
    @extend .w-full;
  }
  &-header,
  &-footer {
    @include padding(12);
  }
  &-header {
    .left-sidebar-search {
      .icon-search {
        top: 11px;
      }
      .form-control {
        background-color: $dropdown_sh1;
        border: 1px solid $dropdown_sh1;
        @include padding-left(38);

        &:hover,
        &:focus {
          background-color: $blue_sh8;
          border-color: rgba($white, 0.2);
        }
      }
    }
  }
  &-body {
    &-content {
      height: 100%;
      max-height: 260px;
      overflow-y: auto;
      overflow-x: hidden;
      @include padding(0 12 0 12);
      @extend .easing;
      overscroll-behavior-y: none !important;
      scrollbar-color: $scrollBar transparent;

      &-wrap {
        @extend .w-100;

        .ps-12 {
          color: $tertiary;
        }
        .digit {
          color: $white;
          max-width: 100px;
          text-align: right;
          padding-left: 10px;
        }

        .sidebar-menu-list {
          padding-block-end: 0.75rem;

          .sidebar-menu-item {
            margin: 0;

            .sidebar-menu-link {
              height: 44px;
              @include padding(6);
              @extend .u-flexed;
              @extend .u-justify-btw;
              @extend .radius-xs;
              border: 0 none;

              &::before {
                display: none;
              }

              > div {
                min-width: 80px;
              }

              .size-14px {
                padding: 0;
                flex-shrink: 0;
                @include size(14px);
                @include minSize(14px);
                @extend .o-hidden;
                @extend .radius-xxs;
                flex: 0 0 16px;
                border-radius: 50%;
                filter: none !important;

                svg,
                img {
                  @include size(14px);
                  @include minSize(14px);
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              .coin-name {
                @extend .fs-14;
                color: $tertiary;
                line-height: 20px;
                @include padding(0 4 0 8);
                @extend .fw-semibold;
                @extend .primaryFont;
                max-width: 175px;
                text-overflow: ellipsis;
                @extend .o-hidden;
                white-space: nowrap;
              }

              .number {
                @extend .fw-normal;
              }

              &:hover,
              &:focus {
                color: $success;
                background: linear-gradient(
                  90deg,
                  rgba($success, 0.1) 0%,
                  rgba($success, 0.1) 100%
                );
                box-shadow: none !important;

                .digit,
                .coin-name {
                  color: $success;
                }
              }
            }
          }
        }
      }
    }
  }

  .hr {
    @include margin(12 0);

    hr {
      margin: 0;
      border-color: rgba($white, 0.125);
    }
  }

  .form-switch {
    margin: 0;
    padding: 0;

    .form-label {
      margin: 0;
      padding: 0;
      max-height: 20px;
    }

    .form-check-input {
      margin: 0;
      padding: 0;
      border-color: rgba($black_sh1, 0.5);
      background-color: rgba($black_sh1, 0.5);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c0bfc8'/%3e%3c/svg%3e");
      transition: none !important;

      &:checked {
        border-color: rgba($success, 0.1);
        background-color: rgba($success, 0.3);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231ed760'/%3e%3c/svg%3e");
        transition: none !important;
      }
    }
  }
}

.notify-list {
  $notifyListSelf: &;

  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1.25rem;

  > div {
    overflow-x: hidden !important;

    div {
      &:nth-child(2) {
        display: none !important;
        overflow-x: hidden !important;
      }
    }
  }

  #{$notifyListSelf}-items {
    @extend .notify-list;
    margin-top: 0;
    @include margin-right(16);
    @include margin-bottom(12);
    @include padding-bottom(12);
    border-bottom: 1px solid rgba($white, 0.1);

    &:last-of-type {
      border-bottom: 0 none;
      @include margin-bottom(0);
      // @include padding-bottom(0);
    }

    h3 {
      color: $white;
      font-size: 14px;
      position: relative;
      @include margin-bottom(6);
      @extend .primaryFont;

      &::before {
        top: 5px;
        left: -16px;
        content: "";
        @include size(5px);
        position: absolute;
        @extend .radius-xl;
        background-color: $success;
      }
    }
    p {
      color: $white;
      font-size: 14px;
      font-family: $tertiaryFont !important;
      @include opacity(0.5);
    }
    .time {
      font-size: 12px;
      text-align: right;
      @extend .text-hover;
    }

    &.empty-state {
      .notify-list-wrap {
        text-align: center;
        @include padding-left(0);
        @include padding-bottom(8);
      }

      h3 {
        &::before {
          display: none;
        }
      }
      h3,
      p,
      time,
      .time {
        @include opacity(1);
      }
    }
  }

  #{$notifyListSelf}-wrap {
    width: 100%;
    @include padding-left(16);
  }

  #{$notifyListSelf}-content {
    @extend .pointer;
    h3,
    p,
    time,
    .time {
      @include opacity(0.45);
      @extend .easing;
    }

    &:hover,
    &:active {
      h3,
      p,
      time,
      .time {
        @include opacity(1);
      }
    }
  }
}

.profile-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &-items {
    @extend .profile-list;
    @include margin-bottom(20);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &-link {
    color: $white;
    @include padding(0);
    @include font-size(14);
    @extend .primaryFont;

    &:hover,
    &:active {
      @extend .text-hover;
    }
  }
  .logout {
    &:hover,
    &:active {
      @extend .text-danger;
    }
  }
}

.header-circle-progress {
  @include size(32px);
  position: relative;
  @extend .radius-circle;
  @extend .vc;

  .icon-award {
    top: 50%;
    left: 50%;
    position: absolute;
    @include transform(translate(-50%, -50%));
  }

  .CircularProgressbar {
    @include size(32px);
    @extend .vc;

    &-trail {
      stroke-width: 3px;
      stroke: rgba($white, 0.2);
    }
    &-path {
      stroke-width: 5px;
      stroke: $success;
      stroke-linecap: butt;
    }
    &-background {
      fill: $success;
    }
    &-text {
      fill: $yellow;
    }
  }
}

@media (min-width: 992px) {
  .lg\:hidden {
    display: none;
  }
}
.z-50 {
  z-index: 50;
}

@media (max-width: 991px) {
  .mobile-toggler {
    top: 12px;
    right: 1.25rem;
    position: absolute;
  }
}

// Navigation
.header {
  .navigation {
    height: 36px;
    @extend .w-full;
    position: relative;
    @include padding-left(64);
    @include padding-right(5);

    @media (max-width: 991px) {
      display: none !important;
    }

    &-list {
      margin: 0;
      padding: 0;
      @extend .w-full;
      list-style: none;
      @extend .u-inline-flexed;
      @extend .u-align-center;
    }

    &-item {
      margin: 0;
      padding: 0;
      @include margin-left(20);

      &:first-of-type {
        margin-left: 0;
      }
    }

    &-link {
      font-size: 14px;
      line-height: 36px;
      @extend .text-white;
      @extend .primaryFont;
      position: relative;
      @extend .easing;
      @extend .pointer;
      @include opacity(0.5);

      &::before {
        left: 0;
        right: 0;
        width: 0;
        height: 1px;
        content: "";
        bottom: -23px;
        position: absolute;
        background-color: currentColor;
        @extend .easing;
      }

      &:hover,
      &:active {
        @extend .text-white;
        @include opacity(1);

        &::before {
          width: 100%;
          @include opacity(1);
        }
      }
      &.is-active {
        @extend .text-hover;
        @include opacity(1);

        &::before {
          width: 100%;
          @include opacity(1);
        }
      }
    }
  }
}

@media (max-width: 567px) {
  .hidden-xs-device {
    display: none;
  }
}

.header {
  &.is-mmenu-enabled {
    .header-main,
    .header-right {
      z-index: 21 !important;
    }

    &::before {
      z-index: 21 !important;
    }
  }
}

@media (max-width: 767px) {
  .header {
    &-btn {
      &-wallet {
        @include size(48px);
        @include maxSize(48px);
        @include padding(10);

        > div {
          align-items: center !important;
          justify-content: center !important;
        }

        .ps-12 {
          display: none;
        }
      }
      &-profile {
        background-color: $black_sh1;
      }
      &-desktop {
        display: none !important;
      }
    }
    &-right {
      .header-btn {
        &:only-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .header {
    &-btn {
      &-mobile {
        display: none !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .is-user-loggedin {
    .header {
      &-main {
        .auth-logo {
          display: none;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  70% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// Badges Variants
[class*="None-Rating"],
[class*="None-Rating-"],
[class*="Wood-Rating-"],
[class*="Bronze-Rating-"],
[class*="Silver-Rating-"],
[class*="Gold-Rating-"],
[class*="Platinum-Rating-"],
[class*="Sapphire-Rating-"],
[class*="Emerald-Rating-"],
[class*="Ruby-Rating-"],
[class*="Diamond-Rating-"],
[class*="Diamond2-Rating-"] {
  @include size(24px);
  @extend .vc;
  @extend .o-hidden;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  @extend .flex-shrink-0;
}

.size-40px {
  [class*="None-Rating"],
  [class*="None-Rating-"],
  [class*="Wood-Rating-"],
  [class*="Bronze-Rating-"],
  [class*="Silver-Rating-"],
  [class*="Gold-Rating-"],
  [class*="Platinum-Rating-"],
  [class*="Sapphire-Rating-"],
  [class*="Emerald-Rating-"],
  [class*="Ruby-Rating-"],
  [class*="Diamond-Rating-"],
  [class*="Diamond2-Rating-"] {
    @include size(40px);
    background-size: 40px 40px;
  }
}

.size-48px {
  [class*="None-Rating"],
  [class*="None-Rating-"],
  [class*="Wood-Rating-"],
  [class*="Bronze-Rating-"],
  [class*="Silver-Rating-"],
  [class*="Gold-Rating-"],
  [class*="Platinum-Rating-"],
  [class*="Sapphire-Rating-"],
  [class*="Emerald-Rating-"],
  [class*="Ruby-Rating-"],
  [class*="Diamond-Rating-"],
  [class*="Diamond2-Rating-"] {
    @include size(48px);
    background-size: 48px 48px;
  }
}

.size-16px {
  [class*="None-Rating"],
  [class*="None-Rating-"],
  [class*="Wood-Rating-"],
  [class*="Bronze-Rating-"],
  [class*="Silver-Rating-"],
  [class*="Gold-Rating-"],
  [class*="Platinum-Rating-"],
  [class*="Sapphire-Rating-"],
  [class*="Emerald-Rating-"],
  [class*="Ruby-Rating-"],
  [class*="Diamond-Rating-"],
  [class*="Diamond2-Rating-"] {
    @include size(16px);
    background-size: 16px 16px;
  }
}

.flex-cell,
.chat-list-items {
  [class*="None-Rating"],
  [class*="None-Rating-"],
  [class*="Wood-Rating-"],
  [class*="Bronze-Rating-"],
  [class*="Silver-Rating-"],
  [class*="Gold-Rating-"],
  [class*="Platinum-Rating-"],
  [class*="Sapphire-Rating-"],
  [class*="Emerald-Rating-"],
  [class*="Ruby-Rating-"],
  [class*="Diamond-Rating-"],
  [class*="Diamond2-Rating-"] {
    @include size(16px);
    background-size: 16px 16px;
  }
}

.currency-dropdown {
  .size-14px,
  .size-16px {
    filter: none !important;
  }
}

// .profile-details-bottom {
//   .size-16px {
//     > span {
//       filter: grayscale(1);
//     }
//   }
// }

@media (max-width: 767px) {
  .is-user-loggedin {
    .header {
      z-index: 79 !important;

      .profile-dropdown .profile-content {
        max-height: calc(100vh - 325px);
      }
    }
  }
}

/* End header style(s) */
