/* Begin Custom fonts face files
======================== */
@import "partials/variables";
@import "partials/custom";

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.if-brand {
  font-family: "Font Awesome 5 Brands";
  font-weight: normal;
}

.if-regular {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.if-solid {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

$iconfont: "Font Awesome 5 Free";

// Font Sizing
// ================================
$baseFontSize: 1rem;
$uppercase: uppercase;

// Styles
// ================================

/* Primary Fonts Style */
$primaryFont: "chillax", sans-serif;
$secondaryFont: "chillax", sans-serif;
$tertiaryFont: "chillax", sans-serif;
$normalFont: "chillax", sans-serif;

$weightThin: 100;
$weightExtraLight: 200;
$weightLight: 300;
$weightRegular: 400;
$weightMedium: 500;
$weightSemibold: 600;
$weightBold: 700;
$weightExtraBold: 800;
$weightBlack: 900;

b,
.bold,
strong,
.strong {
  font-weight: $weightBold;
  font-family: $secondaryFont;
}

.fw-semibold {
  font-weight: $weightSemibold;
}

.normalFont,
.normal-font,
.normal-text {
  font-family: $normalFont !important;
}

.lh-1 {
  line-height: 1.5 !important;
}

.lh-24 {
  line-height: 1.5rem !important;
}

em,
.em {
  font-style: italic;
}

// Text Decoration
// ================================
.txt-dec-none {
  text-decoration: none;
}

// Defaults
// ================================
html,
body {
  color: $greyish;
  font-family: $primaryFont;
  font-size: $baseFontSize;
}

// Headings
// ================================

.primaryFont,
.primary-font {
  font-family: $primaryFont !important;
}
.secondaryFont,
.secondary-font,
.form-section h2 {
  font-family: $secondaryFont !important;
}
.tertiaryFont,
.tertiary-font {
  font-family: $tertiaryFont !important;
}

.upper {
  text-transform: uppercase;
}

.fw-black {
  font-weight: $weightBlack !important;
}
.fw-bold {
  font-weight: $weightBold !important;
}
.fw-semibold {
  font-weight: $weightSemibold !important;
}
.fw-medium {
  font-weight: $weightMedium !important;
}
.fw-regular {
  font-weight: $weightRegular !important;
}

.fs-italic {
  font-style: italic !important;
}

.u {
  &-text-white {
    color: $white !important;
    @extend .fw-semibold;
  }

  &-text-success {
    color: $success !important;
  }

  &-text-offwhite {
    color: $blue_sh7 !important;
  }

  &-text-danger {
    color: $red !important;
  }
}

// Links
// ================================
a,
a:link,
a:visited,
a:active {
  text-decoration: none;
}

.text {
  &-primary {
    color: $blue_sh1 !important;
  }
  &-success {
    color: $success !important;
  }
  &-info {
    color: $info !important;
  }
  &-warning {
    color: $warning !important;
  }
  &-danger {
    color: $danger !important;
  }
  &-secondary {
    color: $secondary !important;
  }
  &-tertiary {
    color: $tertiary !important;
  }
  &-grey {
    color: $greyish !important;
  }
  &-grey2 {
    color: $white_sh3 !important;
  }
  &-purple {
    color: $purple !important;
  }
  &-pink {
    color: $pink_sh1 !important;
  }
  &-greyish {
    color: $lightergrey !important;
  }
}

.link {
  &-primary {
    color: $success !important;
    border-bottom: 1px solid currentColor;
    text-decoration: none !important;
    @extend .easing; 

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.lh-24 {
  line-height: 24px;
}

.new-line {
  white-space: pre-line;
}

.fs {
  &-0 {
    font-size: 0 !important;
  }
  &-10 {
    font-size: 10px !important;
  }
  &-11 {
    font-size: 11px !important;
  }
  &-12 {
    font-size: 12px !important;
  }
  &-13 {
    font-size: 13px !important;
  }
  &-14 {
    font-size: 14px !important;
  }
  &-15 {
    font-size: 15px !important;
  }
  &-16 {
    font-size: 16px !important;
  }
  &-17 {
    font-size: 17px !important;
  }
  &-18 {
    font-size: 18px !important;
  }
}





.title {
  @include font-size(16);
  line-height: 1.5;
}

.fs-24 {
  @include font-size(22);
  line-height: 1.5;

  @include responsive(sm) {
    @include font-size(22);
    line-height: 1.5;

    @include respond-to(md) {
      @include font-size(22);
      line-height: 1.5;

      @include respond-to(lg) {
        @include font-size(24);
        line-height: 1.5;
      }
    }
  }
}
.fs-75 {
  @include font-size(45);
  line-height: 1.15;

  @include responsive(sm) {
    @include font-size(45);
    line-height: 1.15;

    @include respond-to(md) {
      @include font-size(55);
      line-height: 1.15;

      @include respond-to(lg) {
        @include font-size(65);
        line-height: 1.15;

        @include respond-to(xl) {
          @include font-size(75);
          line-height: 1.15;
        }
      }
    }
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

hr.space {
  border: none;
  height: 50px;
}
.space {
  clear: both;
  width: 100%;
  height: 60px;
  display: block;
  position: relative;

  hr {
    clear: both;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.hr {
  hr {
    clear: both;
    @include margin-top(20);
    @include margin-bottom(20);
    border-top: 1px solid $border2;
    @include opacity(1);
  }
}

address {
  line-height: 1.5;
  font-style: normal;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

/* Typography
------------------------------------*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: $weightRegular;
  @extend .secondaryFont;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  font-weight: $weightRegular;
  @extend .secondaryFont;
  @extend .fw-medium;
}

.numeric {
  font-family: $tertiaryFont !important;
  font-weight: $weightMedium !important;
}

h1,
.h1 {
  @include font-size(32);
  line-height: 1;
  @extend .fw-medium;

  @include responsive(sm) {
    @include font-size(35);
    line-height: 1;

    @include respond-to(md) {
      @include font-size(40);
      line-height: 1;

      @include respond-to(lg) {
        @include font-size(46);
        line-height: 1;
      }
    }
  }
}
h2,
.h2 {
  @include font-size(30);
  line-height: 1.25;
  font-weight: $weightBold;

  @include responsive(sm) {
    @include font-size(34);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(37);
      line-height: 1;

      @include respond-to(lg) {
        @include font-size(40);
        line-height: 1;
      }
    }
  }
}
h3,
.h3 {
  @include font-size(26);
  line-height: 1.1;
  font-weight: $weightBold;

  @include responsive(sm) {
    @include font-size(30);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(33);
      line-height: 1.1;

      @include respond-to(lg) {
        @include font-size(36);
        line-height: 1.1;
      }
    }
  }
}
h4,
.h4 {
  @include font-size(20);
  line-height: 1.25;
  font-weight: $weightBold;

  @include responsive(sm) {
    @include font-size(22);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(24);
      line-height: 1.25;

      @include respond-to(lg) {
        @include font-size(24);
        line-height: 1.25;
      }
    }
  }
}
h5,
.h5 {
  @include font-size(19);
  line-height: 1.5;

  @include responsive(sm) {
    @include font-size(20);
    line-height: 1.5;

    @include respond-to(md) {
      @include font-size(20);
      line-height: 1.5;

      @include respond-to(lg) {
        @include font-size(22);
        line-height: 1.5;
      }
    }
  }
}

.text-red {
  color: $red !important;
}
.text-red_sh1 {
  color: $red_sh1 !important;
}
.text-white {
  color: $white !important;
}
.text-blue {
  color: $blue !important;
}
.text-grey {
  color: $greyish !important;
}
.text-greyish {
  color: $lightergrey !important;
}
.text-hover {
  color: $hoverColor !important;
}
.text-white {
  color: $white !important;
}
.text-white-50 {
  color: rgba($white, 0.5) !important;
}

.fs--13 {
  @include font-size(13);
  line-height: 1.4;
}

.fs--14 {
  @include font-size(14);
  line-height: 1.5;
}

p {
  @include font-size(16);
  line-height: 1.5;
  @include margin-bottom(20);

  &:last-child,
  &:last-of-type,
  &:only-child {
    @include margin-bottom(0);
  }

  @media (max-width: 767px) {
    @include font-size(15);
    line-height: 1.5;
  }
}

ul,
ol {
  position: relative;

  li {
    @include font-size(16);
    line-height: 1.5;
    @include margin-bottom(10);

    a {
      color: $blue_sh1;
      &:hover {
        text-decoration: none;
      }
    }

    &:last-child,
    &:last-of-type {
      @include margin-bottom(0);
    }

    @media (max-width: 767px) {
      @include font-size(15);
      line-height: 1.5;
    }
  }
}

.marker-list li {
  list-style: square;
  list-style-position: inside;
}

iframe {
  border: none;
}

q {
  @include font-size(16);
  font-style: italic;
  color: $black;

  @media (max-width: 767px) {
    @include font-size(15);
    line-height: 1.5;
  }
}

.small,
small {
  font-size: 75%;
  font-weight: 400;
  color: rgba($greyish, 0.75);
}

.heading-text {
  @include margin-bottom(30);

  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4 {
    @include font-size(40);
    line-height: 1.25;
    @include margin-bottom(30);

    &::before {
      left: 0;
      right: 0;
      content: "";
      width: 30px;
      height: 3px;
      bottom: -10px;
      margin: 0 auto;
      position: absolute;
      background-color: $grey_sh1;
    }

    @media (max-width: 767px) {
      @include font-size(30);
      line-height: 1.25;
    }
  }
}
