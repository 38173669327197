/* Begin button style */

.btn,
button {
  outline: none !important;
  box-shadow: none !important;
  user-select: none !important;
}

.c-btn {
  margin: 0;
  padding: 0;
  border: 0 none;
  background: 0 0;
  font-family: inherit;
  outline: none;
  text-align: inherit;
  line-height: inherit;
  cursor: pointer;
  color: $white;
  font-size: 13px;
  @include padding(8 32);
  text-align: center;
  line-height: 1.25;
  border-radius: 100px;
  text-transform: capitalize;
  @extend .fw-semibold;
  background-color: $transparent;
  @extend .u-inline-flexed;
  @extend .u-justify-center;
  @extend .u-align-center;
  @extend .pointer;
  @extend .easing;

  &-transparent {
    color: $white;
    border: 0 none;
    background-color: $transparent;
    @include size(100%);
  }
}

.c-btn {
  @extend .easingOut;
  @extend .radius-xs;
  // @extend .tertiaryFont;

  &-md {
    min-height: 48px;
    @include font-size(16);
    line-height: 24px;
    font-weight: 400;
    @include padding(12 25);
    @extend .radius-xs;
  }

  &-lg {
    @include font-size(18);
    line-height: 22px;
    font-weight: 400;
    @include padding(15 35);
  }

  &-success {
    border-color: $success;
    background-color: $success;

    @media (hover: hover) {
      &:hover {
        border-color: lighten($success, 2.5%);
        background-color: lighten($success, 2.5%);
      }
    }
  }

  &-outline {
    &-success {
      color: $success !important;
      border: 1px solid $success !important;
      background-color: rgba($success, 0.1) !important;

      @media (hover: hover) {
        &:hover {
          color: $white;
          border: 1px solid $success !important;
          background-color: rgba($success, 0.2) !important;
        }
      }
    }
  }

  &-purple {
    color: $white;
    border-color: $purple;
    background-color: $purple;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: lighten($purple, 2.5%);
        background-color: lighten($purple, 2.5%);
      }
    }
  }

  &-danger {
    color: $white;
    border-color: $danger !important;
    background-color: $danger !important;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: lighten($danger, 2.5%) !important;
        background-color: lighten($danger, 2.5%) !important;
      }
    }
  }

  &-warning {
    color: $white;
    border-color: $warning !important;
    background-color: $warning !important;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: darken($warning, 15%) !important;
        background-color: darken($warning, 15%) !important;
      }
    }
  }

  &-yellow {
    color: $white;
    border-color: $yellow;
    background-color: $yellow;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: lighten($yellow, 2.5%);
        background-color: lighten($yellow, 2.5%);
      }
    }
  }

  &-primary {
    color: $white;
    border-color: $info;
    background-color: $info;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: lighten($info, 2.5%);
        background-color: lighten($info, 2.5%);
      }
    }
  }

  &-secondary {
    color: $white;
    border-color: $secondary;
    background-color: $secondary;

    @media (hover: hover) {
      &:hover {
        color: $white;
        border-color: lighten($secondary, 2.5%);
        background-color: lighten($secondary, 2.5%);
      }
    }
  }

  // Outline(s)
  &-outline {
    &-secondary {
      color: $white;
      @include font-size(13);
      line-height: 1;
      border: 1px solid rgba($white, 0.4);
      background-color: rgba($white, 0.3) !important;
      @extend .radius-xxl;
      font-family: $primaryFont !important;

      @media (hover: hover) {
        &:hover {
          color: $white !important;
          border-color: rgba($white, 0.6);
          background-color: rgba($white, 0.5) !important;
        }
      }
    }
  }

  &:disabled {
    @include opacity(0.5);
    pointer-events: none;
  }

  &-transparent {
    border: 0 none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &-rounded {
    @extend .radius-xl;
  }

  &-wallet,
  &-opensea {
    @extend .tertiaryFont;
  }
  &-wallet {
    color: $black;
    border: 0 none !important;

    &:hover,
    &:active {
      color: $success;
      background-color: $black !important;
      @extend .backdrop-md;
    }
  }
  &-opensea {
    color: $white;
    background-color: rgba($white, 0.15) !important;
    @extend .backdrop-md;

    &:hover,
    &:active {
      color: $black;
      background-color: rgba($white, 0.75) !important;
      @extend .backdrop-md;
    }
  }

  &-inline {
    @extend .u-inline-flexed;
  }

  &-block {
    width: 100%;
  }

  &-lg {
    @include padding(13 50);
    @include font-size(40);
    line-height: 1;
    letter-spacing: 0;
    // letter-spacing: -1.2px;
  }

  &-md {
    @include padding(11 20);
  }

  &-outline {
    &-secondary {
      color: $white !important;
      @include font-size(13);
      line-height: 1;
      border: 1px solid rgba($white, 0.4);
      background-color: rgba($white, 0.3) !important;
      @extend .radius-xxl;
      font-family: $primaryFont !important;
    }
  }

  &-buy,
  &-filter,
  &-settings {
    min-height: 34px;
    color: $white;
    @extend .fs-13;
    line-height: 1.35;
    @extend .fw-medium;
    @include border-radius(20px);
    border: 1px solid $border2;
    background-color: $primary;
    padding: 0.25rem 0.875rem;
    @extend .easing;
    @extend .vc;

    &:hover {
      border-color: rgba($hoverColor, 0.15);
      background-color: rgba($hoverColor, 0.15);
    }

    .icon-arrow {
      @extend .easing;
    }

    &.is-opened {
      color: $hoverColor;
      border-color: rgba($hoverColor, 0.15);
      background-color: rgba($hoverColor, 0.15);

      .icon-arrow {
        @include transform(rotate(180deg));
      }
    }
  }

  &-buy {
    line-height: inherit;
    @extend .u-upper;
    @extend .fw-semibold;

    &:hover {
      border-color: rgba($hoverColor, 0.5);
      background-color: rgba($hoverColor, 0.5);
      color: $white;
    }
  }

  &-settings {
    background-color: $border2;

    &:hover {
      border-color: rgba($hoverColor, 0.15);
      background-color: rgba($hoverColor, 0.15);
    }
  }
}

.buttons-group {
  .btn {
    + .btn {
      @include margin-top(35);

      @media (max-width: 767px) {
        @include margin-top(25);
      }
    }
  }
}

.modal-body {
  .btn {
    & + .btn {
      margin-left: 1rem;
    }
  }
}

@media (hover: hover) {
  .has-shadow,
  [class*="has-"] {
    &:hover {
      box-shadow: 0 14px 26px -12px rgba($primary, 0.1), 0 4px 23px 0 rgba($primary, 0.1),
        0 8px 10px -5px rgba($primary, 0.1) !important;
    }
  }
}

.btn,
button,
.button {
  &.is-loading {
    padding-right: 45px !important;
  }
  &:not(.button-block) {
    &.is-loading {
      padding-right: 0 !important;
    }
  }
}

.button-xs {
  &.is-loading {
    min-width: 50px;
    max-width: 50px;
    cursor: default;
    &::before {
      display: none;
    }
  }
}

.is-loading {
  position: relative;
  @include transition(all 0.2s ease);

  span {
    @include opacity(0);
  }

  &::after {
    top: 50%;
    right: 15px;
    opacity: 1;
    content: "";
    @include size(18px);
    margin-top: -10px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    border-top: 2px solid $primary;
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
  }
}
.btn-success.is-loading::before {
  border-left-color: var(--color-green);
}
.btn-danger.is-loading::before {
  border-left-color: var(--color-error);
}
.btn-warning.is-loading::before {
  border-left-color: var(--color-warning);
}

.expiring-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

@keyframes load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .sm-btn-block {
    width: 100%;
  }
}

.spinner-border {
  &.text-dark {
    color: $black_sh10 !important;
  }
}

.spinner-border-sm {
  --bs-spinner-border-width: 0.15em !important;
}
.wallet-address-block {
  .spinner-border-sm {
    --bs-spinner-border-width: 0.15em !important;
  }
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-500 {
  transition-duration: 0.5s;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
}

.hamburger {
  cursor: pointer;
  position: relative;
  user-select: none;
  @include size(32px);
  @extend .vc;

  .navicon {
    display: block;
    width: 1.125rem;
    height: 0.094rem;
    position: relative;
    background-color: $white;
    @include transition(all 0.2s ease-out);

    &::before,
    &::after {
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      position: absolute;
      background-color: $white;
      @include transition(all 0.2s ease-out);
    }

    &::before {
      top: 6px;
    }
    &::after {
      top: -6px;
    }
  }

  &.is-opened {
    .navicon {
      background-color: transparent;

      &::before,
      &::after {
        top: 0;
        background-color: $hoverColor;
      }

      &::before {
        @include transform(rotate(-45deg));
      }
      &::after {
        @include transform(rotate(45deg));
      }
    }
  }
}

.btn-spring {
  &:active {
    @include transform(scale(0.98) !important);
    * {
      @include transform(scale(0.98) !important);
    }
  }
}

.multi-select {
  .badge {
    height: 18px;
    @include padding(1 4);
    @extend .u-inline-flexed;
    @extend .u-align-center;

    &.bg-success {
      color: $success !important;
      background-color: rgba($success, 0.2) !important;
      @extend .radius-xs;
      line-height: 16px;
      @extend .fs-14;
      @extend .fw-bold;
      @extend .primary-font;
      text-shadow: 0px 0px 2px rgba($success, 0.4);
    }
  }

  .dropdown-heading {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    height: 40px !important;
    min-height: 40px !important;
    border: 0 none !important;
    @include padding(8 12);
    background: $primary;
    @include linear-gradient((0deg, $primary 0%, $primary 100%), $primary);
    @extend .radius-xs;
    @extend .u-flexed;
    @extend .u-align-center;
    @extend .fs-14;
    @extend .normal-font;
    @extend .fw-semibold;
    box-shadow: none !important;
    @extend .pointer;

    .clear-selected-button svg,
    .dropdown-heading-dropdown-arrow {
      display: none !important;
    }

    .clear-selected-button {
      margin-right: 4px;
      @include size(16px !important);

      &::before {
        content: "";
        position: absolute;
        @include size(16px);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M13 4.3 11.7 3 8 6.7 4.3 3 3 4.3 6.7 8 3 11.7 4.3 13 8 9.3l3.7 3.7 1.3-1.3L9.3 8 13 4.3z'/%3E%3C/svg%3E");
        background-size: 12px 12px;
        background-position: center;
      }
    }

    &::after {
      content: "";
      margin-left: auto;
      @extend .radius-xxs;
      border: 0 none;
      @include size(16px);
      background-color: $black_sh1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E");
      background-size: 16px 16px;
      background-position: center;
    }

    &-value {
      height: 24px;
      line-height: 24px;

      > span {
        min-width: 0;
        text-transform: capitalize;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;

        > div {
          flex-shrink: 0;
          @include margin-right(6);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      span.gray {
        font-size: 0;
        @extend .fs-14;
        color: $white;
        @extend .normal-font;
        @extend .fw-semibold;

        // &::before {
        //   @extend .fs-14;
        //   color: $white;
        //   @extend .normal-font;
        //   @extend .fw-semibold;
        //   content: "All Providers";
        // }
      }
    }
  }
  .dropdown-container {
    padding: 0 !important;
    border: 0 none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    @extend .radius-xs;
  }

  .dropdown-content {
    @extend .w-full;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 0.5rem !important;
    max-height: 425px;
    background: $primary !important;
    @include linear-gradient((0deg, rgba($white, 0.05) 0%, rgba($white, 0.05) 100%), $primary !important);
    @extend .radius-xs;

    .panel-content {
      @include padding(8 12);
      background-color: $primary !important;

      .select-panel {
        .options {
          max-height: 360px;
          @extend .scrollY;
        }
      }
    }

    // .search {
    //   display: none !important;
    // }
  }

  .select-item {
    padding: 0.5rem 0.25rem !important;

    &.selected,
    &:hover {
      color: $success;
      background-color: $primary !important;
    }
  }

  .item-renderer {
    @extend .u-flexed;
    @include align-items(center !important);

    input {
      flex-shrink: 0 !important;
      margin: 0 0.625rem 0 0 !important;
      @include size(1.125rem !important);
      margin-top: 0.25em;
      vertical-align: top;
      background-color: $black_sh1 !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @extend .border-0;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      @extend .radius-xxs;

      &:checked {
        background-color: rgba($success, 0.2) !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231ed760'%3E%3Cpath d='M7.1 11.4c-.2 0-.4 0-.6-.3L3 7.6l1.2-1.2 2.9 2.9 4.6-4.6 1.2 1.2-5.3 5.3c-.2.2-.4.3-.6.3Z'/%3E%3C/svg%3E");
        background-size: 16px 16px;
      }
    }
  }
}

.multi-select,
.common-dropdown {
  @extend .w-full;
  position: relative;

  .dropdown-toggle {
    height: 40px;
    border: 0 none !important;
    @include padding(8 12);
    background-color: $primary !important;
    @extend .radius-xs;
    @extend .u-flexed;
    @extend .u-align-center;
    @extend .fs-14;
    @extend .normal-font;
    @extend .fw-semibold;

    &::after {
      margin-left: auto;
      background-color: $black_sh1;
      @extend .radius-xxs;
    }
  }

  .dropdown-menu {
    @extend .w-full;
    @include padding(12);
    margin-top: 0.5rem;
    background-color: $primary;
    @extend .radius-xs;
    max-height: 425px;

    .dropdown-item {
      color: $tertiary;
      @extend .fs-14;
      @extend .secondary-font;
      @extend .fw-semibold;
      margin-bottom: 0.125rem;

      &:hover {
        color: $tertiary !important;
        background-color: rgba($white, 0.035) !important;
      }
      &.is-selected {
        color: $tertiary !important;
        background-color: rgba($white, 0.035) !important;
      }
    }
  }
}

.btn-logout {
  color: $white !important;
  border-color: $red !important;
  background-color: $red !important;

  &:hover,
  &.is-active,
  &.is-selected {
    color: $white !important;
    background-color: darken($red, 5%) !important;
  }
  &:active {
    color: $white !important;
    background-color: lighten($red, 5%) !important;
  }
}

.success {
  &-opacity {
    color: var(--bs-btn-disabled-color) !important;
    background-color: var(--bs-btn-disabled-bg) !important;
    border-color: var(--bs-btn-disabled-border-color) !important;
    opacity: var(--bs-btn-disabled-opacity) !important;
  }
}

.btn-load {
  color: $white;
  @extend .vc;
  @include padding(6 16);
  background-color: $black_sh1 !important;
  min-width: 164px;
  height: 40px;
  @extend .primary-font;
  @extend .fw-bold;
  @extend .border-0;

  &:hover,
  &:active,
  &.is-active {
    color: $white;
    background-color: darken($black_sh1, 2%) !important;
  }
  &:active {
    color: $white;
    background-color: lighten($black_sh1, 2%) !important;
    @include transform(scale(0.98) !important);

    * {
      @include transform(scale(0.98) !important);
    }
  }
}

.form-horizontal-bottom {
  &.btn-login,
  .btn-success {
    color: $black !important;
  }
}

/* End button style */
