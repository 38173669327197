@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@font-face {
    font-family: chillax;
    src: url(../../fonts/Chillax-Regular/Chillax-Regular-73b72f5f77d0eec32f88fbfaa7949e65a69315a5bec56b518b53f86d6fa20061.woff2) format("woff2"),
        url(../../fonts/Chillax-Regular/Chillax-Regular-7067381891d6831853f2dc5093243b0f7663ddd6d368bb23ef2e073374aa1be6.woff) format("woff"),
        url(../../fonts/Chillax-Regular/Chillax-Regular-ae28e5315391ccffd992b8e1761cdc6941c80d2add2a584092d76dbe38db4e35.ttf) format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal
}
@font-face {
    font-family: chillax;
    src: url(../../fonts/Chillax-Medium/Chillax-Medium-d9407c0cb5c0f53f4a6ce89e3f5a991a3ac1393d554793fce9dce9d3dc3c1d54.woff2) format("woff2"),
        url(../../fonts/Chillax-Medium/Chillax-Medium-5ac9671e7aa10f1847bf85eeed3c7a63bcea4a5ac5165d06823918684fd78c7f.woff) format("woff"),
        url(../../fonts/Chillax-Medium/Chillax-Medium-2d9ab6c35594bf9665801974cf5ba28038a3bfb98b4fe3dd987922cddae7e291.ttf) format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: chillax;
    src: url(../../fonts/Chillax-Semibold/Chillax-Semibold-85559bd63ed1506be95ed4463a5c5381db78c0b228eb2afd57e109987ec82ca9.woff2) format("woff2"),
        url(../../fonts/Chillax-Semibold/Chillax-Semibold-d5b2db6cdf8319c5ce02aae28060bd835c1657d3e56e255c11dac6f390b085de.woff) format("woff"),
        url(../../fonts/Chillax-Semibold/Chillax-Semibold-845f6783032b6f1a9fdc5d4be28c973e3eab1ab7c8aa6efa022b8a90df421752.ttf) format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: chillax;
    src: url(../../fonts/Chillax-Bold/Chillax-Bold-f53037b7e04bef2f4d02fe026d2387e7c66582d09ecd8c75cddb441da1c5c759.woff2) format("woff2"),
        url(../../fonts/Chillax-Bold/Chillax-Bold-fd3f4eea3f2aa968c2b063148991485a9ab00389d8941971d8393bde22312091.woff) format("woff"),
        url(../../fonts/Chillax-Bold/Chillax-Bold-5854e6dff0731aace4508dedc3540add91285642c3c4d84acbde43e2f5ffd879.ttf) format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
