/* Begin Hero Card style */

.hero-block {
  width: 100%;
  // min-height: 13.125rem;

  > .hero-card {
    scroll-snap-align: start;
  }
}

.swiper-ios {
  overflow: clip visible !important;
}

.degens-card,
.hero-card {
  color: $white;
  position: relative;
  container-type: inline-size;
  container-name: card-size;
  @extend .u-flexed;
  @extend .u-flex-direction-column;
  @extend .radius-sm;

  > a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    padding: 0;
    background: 0 0;
    border: 0 none;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
  }
  &-wrapper {
    overflow: hidden;
    @extend .u-flexed;
    position: relative;
    @include transform(scale(1));
  }
}

.degens-card {
  &-wrapper {
    min-height: 13.125rem;
    @include transition(all 0.2s ease-in-out);
  }
}

.hero-card {
  &-wrapper {
    padding-bottom: 56.25%;
  }
}

.hero-card {
  overflow: hidden;
  background-color: $black_sh12;

  &-image {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    @extend .u-flexed;
    @extend .u-align-center;
    @extend .u-justify-end;

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    width: 100%;
    max-width: 55%;
    @extend .u-flexed;
    @extend .u-flex-direction-column;
    z-index: 1;
    @include padding(20);

    @media screen and (min-width: 1154px) and (max-width: 1299px) {
      padding-right: 0;
    }

    h3 {
      color: $white;
      @include margin-bottom(12);
    }
    p {
      color: $tertiary;
      line-height: 1.5 !important;
    }

    &-wrap {
      height: 100%;
      @extend .u-flexed;
      @extend .u-align-stretch;
      @extend .u-flex-direction-column;

      &-top,
      &-bottom {
        flex: 0;
      }

      &-top {
        flex: 1 1;
      }
    }
  }

  &-btn {
    height: 40px;
    @include padding(8 16);
    @extend .radius-xs;
    background-color: rgba($success, 0.2);
    @extend .u-inline-flexed;
    @extend .u-align-center;
    gap: 6px;
    @extend .fs-14;
    @extend .primaryFont;
    color: $success;
  }
}

.degens-card-slider {
  .swiper {
    padding-left: 0px !important;
    overflow: clip visible !important;
  }
}

.degens-card,
.hero-card {
  &-slider {
    will-change: transform;
    user-select: none;

    .swiper-wrapper {
      > :nth-of-type(1) {
        animation-delay: 0s;
      }
      > * {
        animation: 0.2s ease-out 0s 1 normal both running;
        // animation: 0.2s ease-out 0s 1 normal both running animation-tY10;
        animation-delay: 0s;
      }
    }

    .swiper {
      padding-left: 0px !important;
      overflow: clip visible !important;
    }

    .slick-list {
      margin: 0;
      overflow: clip visible !important;
      padding-left: 0px !important; // half partial

      @media (max-width: 767px) {
        margin: 0;
      }
    }
    .slick-slide {
      flex-shrink: 0;
      padding-right: 1rem;
      box-sizing: border-box !important;
      // scroll-snap-align: start;
      // will-change: opacity;
      transition: all 0.3s;

      @media (max-width: 767px) {
        padding-right: 0.5rem;
      }

      &:not(.slick-current) {
        @include opacity(1);
      }
    }

    // Slick Arrows
    [class*="swiper-button-"] {
      @include size(40px, 32px);
      display: inline-flex;
    }

    .swiper-button-prev,
    .swiper-button-next {
      @include size(40px, 32px);
      @extend .vc;
      @extend .radius-xs;
      background-color: $primary !important;

      &:hover,
      &:active,
      &:focus {
        background-color: $primary !important;
      }

      &::after {
        z-index: 2;
        content: "";
        @extend .o-hidden;
        @extend .radius-xs;
        @include size(40px, 32px);
      }
    }

    // Swiper Arrows
    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        font-size: 0;
        @include size(40px, 32px);
        @extend .vc;
        @include opacity(1);
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        // background-color: transparent !important;

        @media (max-width: 767px) {
          @supports (-webkit-touch-callout: none) {
            background-color: $primary !important;
          }
          @supports not (-webkit-touch-callout: none) {
            background-color: transparent !important;
          }
        }
      }

      // &.swiper-button-disabled {
      //   @include opacity(0);
      //   // @extend .no-pointer;
      //   visibility: hidden;
      // }

      &:hover,
      &:active,
      &:focus {
        background-color: $primary;
        &::after {
          -webkit-transform: scale(1.2) translateZ(0);
          transform: scale(1.2) translateZ(0);

          @media (max-width: 767px) {
            @supports (-webkit-touch-callout: none) {
              -webkit-transform: scale(1) translateZ(0);
              transform: scale(1) translateZ(0);
            }
            @supports not (-webkit-touch-callout: none) {
              -webkit-transform: scale(1.2) translateZ(0);
              transform: scale(1.2) translateZ(0);
            }
          }
        }
      }
    }

    .swiper-button-next {
      left: auto;
      right: 0;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807f91'%3E%3Cpath d='m6.2 2.9 4.6 4.6c.3.3.3.8 0 1.1l-4.6 4.6L5 12l4-4-4-4 1.2-1.1z'/%3E%3C/svg%3E");
      }
      &:hover,
      &:active {
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='m6.2 2.9 4.6 4.6c.3.3.3.8 0 1.1l-4.6 4.6L5 12l4-4-4-4 1.2-1.1z'/%3E%3C/svg%3E");
        }
      }
    }

    .swiper-button-prev {
      left: 0;
      right: auto;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807f91'%3E%3Cpath d='M9.8 13.1 5.3 8.6c-.3-.3-.3-.9 0-1.2l4.6-4.6L11 4 7 8l4 4-1.2 1.1z'/%3E%3C/svg%3E");
      }

      &:hover,
      &:active {
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M9.8 13.1 5.3 8.6c-.3-.3-.3-.9 0-1.2l4.6-4.6L11 4 7 8l4 4-1.2 1.1z'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.degens-card {
  &-wrap {
    position: relative;
    transition: 0.3s;
    @extend .radius-sm;
  }

  .fake-border {
    top: 0;
    left: 0;
    @extend .w-full;
    height: 100%;
    @extend .z-2;
    position: absolute;
    border: 0.125rem solid currentColor;
    @extend .radius-sm;
    @extend .no-pointer;
    display: none !important;
  }

  &-image {
    position: relative;
    will-change: transform;
    transition: 0.3s;
    width: 100%;
    @extend .o-hidden;

    &::before {
      width: 100%;
      content: "";
      display: block;
      padding-top: 135%;
    }

    > img,
    .lazy-load-image-background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      @extend .o-hidden;
      @extend .radius-sm;
      object-fit: cover;
      box-shadow:
        0 4px 6px -1px rgba(27, 23, 23, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.12);

      img {
        height: 100%;
        max-width: 100%;
        user-select: none;
        object-fit: cover;
      }
    }
  }

  &-slider {
    [class*="swiper-button-"] {
      top: -30px;
      @include size(40px, 32px);
      position: absolute;
      display: inline-flex;
    }

    .swiper-button-prev,
    .swiper-button-next {
      @include size(40px, 32px);
      background: $red_sh1;
      background-color: $primary;

      &:focus {
        background-color: $primary;
      }

      &.swiper-button-disabled {
        @include opacity(0.5);
        pointer-events: all;
        // @extend .no-pointer;
        visibility: visible;

        &:hover {
          &::after {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }

    .swiper-button-prev {
      left: auto;
      right: 44px;

      &.swiper-button-disabled {
        &:hover,
        &:active {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807f91'%3E%3Cpath d='M9.8 13.1 5.3 8.6c-.3-.3-.3-.9 0-1.2l4.6-4.6L11 4 7 8l4 4-1.2 1.1z'/%3E%3C/svg%3E");
          }
        }
      }
    }
    .swiper-button-next {
      left: auto;
      right: 0;

      &.swiper-button-disabled {
        &:hover,
        &:active {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807f91'%3E%3Cpath d='m6.2 2.9 4.6 4.6c.3.3.3.8 0 1.1l-4.6 4.6L5 12l4-4-4-4 1.2-1.1z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}

.prividers-card {
  $prividersCardSelf: &;

  #{$prividersCardSelf}-link {
    outline: none;
    display: block;
    @extend .radius-sm;
    overflow: hidden;
    will-change: transform;
    transition: 0.3s;
    @include transform(translate(0));
    transform-origin: 0 0;
    will-change: transform;
  }
  &-wrap {
    min-height: 60px;
    transition: 0.3s;
    position: relative;
    @extend .radius-sm;
    @include padding(12 20);
    background-color: $primary2;
    @extend .vc;
  }
  #{$prividersCardSelf}-image {
    @extend .vc;
    width: 100%;
    height: 36px;
    max-height: 36px;
    max-width: 94px !important;
    border-radius: 0 !important;

    &::before {
      display: none;
    }
    img {
      width: auto !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      max-width: 94px !important;
      height: 100% !important;
      max-height: 36px !important;
      object-fit: contain;
    }
  }

  &:hover {
    #{$prividersCardSelf}-link {
      @include transform(translateY(-4%));
      @include box-shadow(0 0 3px 3px rgba($primary, 0.15));
    }
    #{$prividersCardSelf}-image {
      img {
        @include transform(scale(1));
      }
    }
  }
}

@media (min-width: 768px) {
  .swiper-slide {
    &-visible {
      .hero-card,
      .degens-card,
      .prividers-card {
        // @include opacity(0.4);
        @include opacity(1);
      }

      &:hover {
        .hero-card,
        .degens-card,
        .prividers-card {
          // @include opacity(0.4);
          @include opacity(1);

          .degens-card-link {
            transform: none;
          }
        }
      }

      &.swiper-slide-fully-visible {
        &:hover {
          .hero-card,
          .degens-card,
          .prividers-card {
            @include opacity(1);

            &-link {
              transform: translateY(-4%);
              @include box-shadow(0 0 3px 3px rgba($primary, 0.15));
            }
          }
        }
      }
    }

    &.swiper-slide-active,
    &.swiper-slide-prev,
    &-fully-visible {
      .hero-card,
      .degens-card,
      .prividers-card {
        @include opacity(1);
      }
    }
  }
}

.hero-card {
  &-slider {
    .swiper-button-prev,
    .swiper-button-next {
      &.swiper-button-disabled {
        display: none !important;
      }
    }

    .swiper-slide {
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .hero-block,
    .degens-card-slider {
      .swiper {
        overflow: clip visible !important;
      }

      &::before,
      &::after {
        top: 0;
        z-index: 3;
        width: 10000px;
        height: 100%;
        content: " ";
        position: absolute;
        background-color: $black;
      }
      &::before {
        left: -10000px;
      }
      &::after {
        right: -10000px;
      }
    }
    .swiper {
      overflow: visible !important;
    }
  }
}

.hero-card {
  &-slider {
    [class*="swiper-button-"] {
      opacity: 0 !important;
      visibility: hidden !important;
      @include transition(all 0.1s ease-in-out);

      // Only on Phone Device
      @media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 3) {
        display: none !important;
      }
    }

    &:hover {
      [class*="swiper-button-"] {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
}

/* End Hero Card style */
