@charset "utf-8";
/* CSS Document */

:root {
  --grey-500: #213743;
  --grey-700: #0f212e;
  --header-height: 56px;
  --header-zIndex: 10;
  --mobile-footer-height: 68px;
  --scrollbar-size: 6px;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --small-menu-width: 60px;
}

/* Basic Includes
================================ */
@import "core/reset";
@import "core/variables";
@import "core/mixins";
@import "core/typography";

*,
::before,
::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html {
  @extend .w-full;
  font-size: 100%;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  background-color: $primary;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  scroll-behavior: smooth;
}

html body {
  min-height: 100vh;
}

body {
  color: $white;
  font-size: 16px;
  line-height: 1.5;
  font-family: $primaryFont;
  background-color: $primary;
  font-weight: $weightRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  background: linear-gradient(
    0deg,
    rgba(15, 16, 24, 0) 0%,
    rgba(15, 16, 24, 0) 0%
  );
  background-size: 100% 890px;
  background-repeat: no-repeat;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  #root {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    ::-webkit-scrollbar {
      width: 4px;
      background: transparent !important;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: $scrollBar;
    }
  }
  * {
    scrollbar-color: $scrollBar transparent;
    scrollbar-width: thin;
    scrollbar-width: 4px;
  }

  body ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }
  body ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  body ::-webkit-scrollbar-thumb {
    background: $scrollBar;
    border-radius: 8px;
  }
  @media (hover: hover) {
    body ::-webkit-scrollbar-thumb:hover {
      background: $scrollBar;
    }
  }
  body ::-webkit-scrollbar-thumb:active {
    background: $scrollBar;
  }
  body ::-webkit-scrollbar-track {
    background: transparent;
  }
  @media (hover: hover) {
    body ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
  }
  body ::-webkit-scrollbar-corner,
  body ::-webkit-scrollbar-track:active {
    background: transparent;
  }
}

.page-wrapper {
  @extend .w-full;
  @extend .h-100;

  &.home-page {
    min-height: 100vh;
    @extend .w-full;
    @extend .u-flexed;
    @extend .u-flex-direction-column;
    @extend .u-align-stretch;

    .c-header,
    .c-body,
    .c-footer {
      flex: 0;
      flex-shrink: 0;
    }
    .c-body {
      flex: 1 1;
    }
  }
}

.c-body {
  @extend .w-full;
  @extend .u-flexed;
  @extend .u-flex-direction-column;
  padding-top: var(--header-height);
}

.p-home__line {
  top: 1px;
  width: 1px;
  bottom: 1px;
  height: calc(100% -  2px);
  position: absolute;
  background: rgba($white, 0.1);
  @extend .z-1;

  &--left {
    bottom: 0;
    left: 24px;
  }

  &--right {
    bottom: 0;
    right: 24px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

@media (max-width: 767px) {
  body.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
  }
}

.scrollY,
.scrollX {
  scrollbar-color: $scrollBar transparent;
}
.scrollY {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scroll: touch;
  scrollbar-width: thin;
}

/* Includes
================================ */
@import "objects/objects";
@import "utility/utility";
@import "components/components";
