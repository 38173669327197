//=================================
// RESET
//=================================

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  line-height: 1;
}

*,
*:before,
*:after {
  position: relative;
  box-sizing: border-box;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  font: 100%;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

input,
select {
  vertical-align: middle;
}

input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
    appearance: none;
  }
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button {
  height: auto;
  padding: 0;
  background: transparent;
  outline: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

mark {
  background: #ff0;
  color: #000;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */

  &-focusable {
    &:focus,
    &:active {
      clip: auto !important;
      -webkit-clip-path: none !important;
      clip-path: none !important;
      height: auto !important;
      margin: auto !important;
      overflow: visible !important;
      width: auto !important;
      white-space: normal !important;
    }
  }
}
