/* Begin Modal Style */

:root {
  --bs-modal-sm-width: 368px !important;
  --bs-modal-md-width: 752px !important;
  --bs-modal-lg-width: 800px !important;
  --bs-modal-xl-width: 960px !important;
  --bs-modal-xxl-width: 1000px !important;
  --bs-modal-verify-width: 520px !important;
  --bs-modal-kyc-width: 623px !important;
  --bs-modal-vault-width: 496px !important;
  --bs-backdrop-opacity: 0.7 !important;
}

.fade {
  transition: opacity 0.075s linear !important;
}

.offcanvas {
  @extend .backdrop-md;

  &-backdrop {
    &.show {
      @extend .backdrop-md;
    }
  }
  &-overlay {
    @extend .backdrop-md;
  }
}

.modal {
  // @extend .backdrop-md;
  align-content: center;

  &-backdrop {
    opacity: var(--bs-backdrop-opacity);
  }

  .bg-dark {
    background-color: $primary !important;
  }

  &-content {
    box-shadow: 0 0 25px 10px rgba($black, 0.5);
  }

  &-dialog {
    padding: 0;
    @include transition(width 0.2s ease-in-out);

    @media (max-width: 991px) {
      margin: 0.75rem auto !important;
    }
    @media screen and (min-width: 541px) and (max-width: 767px) {
      margin: 0.75rem auto !important;
      max-width: 450px;
    }
    @media (min-width: 1024px) {
      padding: 0 !important;
    }

    // Size(s)
    &.modal-sm {
      max-width: var(--bs-modal-sm-width);
    }
    &.modal-md {
      max-width: var(--bs-modal-md-width);

      @media screen and (min-width: 541px) and (max-width: 767px) {
        max-width: 550px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 752px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
    &.modal-lg {
      max-width: var(--bs-modal-lg-width);

      @media screen and (min-width: 541px) and (max-width: 767px) {
        max-width: 550px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &.modal-xl {
      max-width: var(--bs-modal-xl-width);

      @media screen and (min-width: 541px) and (max-width: 767px) {
        max-width: 550px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .btn-close {
        position: absolute;
        @extend .z-9;
      }
    }

    &.modal-xxl {
      max-width: unset;
      min-width: 31rem;
      width: min(1000px, 90vw);
      margin-left: auto !important;
      margin-right: auto !important;

      .btn-close {
        position: absolute;
        @extend .z-9;
      }
    }

    &.modal-withdraw,
    &.modal-verify {
      max-width: 520px;
      min-width: 300px;
      // width: min(346px, 32rem);
      margin-left: auto !important;
      margin-right: auto !important;

      @media (max-width: 540px) {
        margin-left: 15px !important;
        margin-right: 15px !important;
      }

      @media screen and (min-width: 541px) and (max-width: 767px) {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .btn-close {
        position: absolute;
        @extend .z-9;
      }

      .modal-content {
        border: 0 none !important;
      }

      .modal-header {
        &-wrap {
          min-height: 24px;
          background-color: $red_sh1;
        }
      }
      .modal-header {
        @include padding(20);
      }
      .modal-body {
        @include padding(0);
        @include padding-top(4);

        .col-right {
          max-width: 100%;

          .nav-tabs {
            overflow: hidden;
            overflow-x: auto;
            // @include padding-bottom(5);
            @include margin(0 20);

            .nav-link {
              white-space: nowrap;
            }
          }
        }

        &-wrap {
          width: 100%;
          height: 100%;
          @extend .u-flexed;
          @extend .u-align-stretch;
          @extend .u-flex-direction-column;
          background-color: $red_sh1;
        }

        &-top {
          flex: 1;
          @include padding(20);
          @extend .u-flexed;
          @extend .u-justify-center;
          @extend .u-flex-direction-column;
        }
        &-bottom {
          flex: 0;
          @include padding(20);
          background-color: $blue_sh8;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }

    &.modal-small {
      .modal-content {
        max-width: var(--bs-modal-sm-width);
      }
    }
  }

  &-content {
    @include padding(0);
    border: 1px solid rgba($primary, 1);
    background-color: rgba($primary, 1);
    @extend .radius-sm;
    @extend .backdrop-md;

    &-wrapper {
      // overflow: hidden;
      margin-bottom: 0;
      background-color: transparent;
      @extend .radius-sm;
    }

    .btn-connect {
      color: $white;
      @include font-size(13);
      line-height: 20px;
      font-weight: 600;
      @include padding(12 20);
      @include padding-right(16);
      letter-spacing: 0 !important;
      font-family: $primaryFont !important;
      border: 1px solid rgba($white, 0.4);
      background-color: rgba($white, 0.3);
      @extend .radius-xxl;
      @extend .u-flexed;
      @extend .u-align-center;
      @extend .u-justify-btw;

      &:hover,
      &:active {
        border: 1px solid rgba($success, 0.4);
        background-color: rgba($success, 0.3);
      }
    }
  }

  &-header,
  &-footer {
    border: 0 none !important;
  }

  &-header {
    color: $white;
    position: relative;
    @include padding(20);
    line-height: 32px;

    @media (max-width: 767px) {
      @include padding(20);
    }

    .size-16px {
      @include size(16px);
      svg {
        @include size(16px);
      }
    }

    .modal-title {
      color: $white;
      @extend .fs-16;
      @extend .fw-bold;
      @extend .primary-font;
      padding-top: 0;
      padding-left: 0;
      line-height: 32px;
      @extend .u-flexed;
      @extend .u-align-center;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &.header-lg {
      .modal-title {
        font-size: 2.25rem;
        line-height: 32px;
        letter-spacing: -0.5px;
        font-family: $tertiaryFont;
      }
    }

    .btn-close {
      top: 20px;
      right: 20px;
      color: $white;
      position: absolute;
      margin-top: 0 !important;
      margin-right: 0 !important;
      @include padding(4);
      @include size(32px);
      @include minSize(32px);
      box-sizing: border-box;
      @extend .radius-xs;
      @extend .border-0;
      background-color: $black_sh1 !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238B8B90'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      background-size: 18px 18px !important;
      flex-shrink: 0;
      @extend .spring;

      // &:hover {
      //   color: $hoverColor;
      //   background-color: rgba($hoverColor, 0.2) !important;
      //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231ed760'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      // }

      // &:hover {
      //   border: 1px solid rgba($white, 0.25);
      //   background-color: rgba($white, 0.05);
      // }

      &:hover,
      &:active,
      &:focus {
        color: $white;
        background-color: lighten($black_sh1, 2%) !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      }

      &:active {
        @include transform(scale(0.95) !important);
        * {
          @include transform(scale(0.95) !important);
        }
      }

      @media (max-width: 767px) {
        top: 20px;
        right: 20px;
      }
    }
  }

  // &-body {
  //   color: $blue_sh4;
  //   @include padding(24);
  //   // overflow-y: auto;
  //   // overflow-x: hidden;
  //   // max-height: calc(100vh - 120px);

  //   @media (max-width: 767px) {
  //     @include padding(24);
  //   }

  //   h3 {
  //     font-size: 1.5rem;
  //     font-weight: $weightBlack !important;
  //     @extend .u-upper;
  //   }

  //   &-inner {
  //     @include padding(30);

  //     @media (max-width: 767px) {
  //       @include padding(20);
  //     }
  //   }
  // }

  &-dialog {
    @media (min-width: 768px) {
      margin-top: 0.75rem !important;
    }
  }

  &.fade {
    .modal-dialog {
      transform: none;
    }
  }
}

.login-modal,
.search-modal {
  .modal-content {
    border: 0 none !important;
  }
  @media (max-width: 991px) {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
}

.modal-dialog {
  &.search-modal {
    .modal-content {
      width: 80% !important;
      max-width: 1140px !important;
      margin-left: auto;
      margin-right: auto;
    }
    &.modal-lg {
      width: inherit !important;
      max-width: inherit !important;
    }
  }
}

.login-modal,
.search-modal {
  .left-sidebar-search {
    width: 100%;
    height: 44px;
    max-width: calc(100% - 56px);

    .form-control {
      height: 44px;
      background-color: $black_sh1 !important;
    }
    .icon-search {
      top: 13px;
    }
  }

  .modal-header {
    padding: 0 !important;
    .modal-title {
      width: 100%;
      display: none;
    }

    .btn-close {
      top: 2rem;
      right: 2rem;
      color: $white;
      position: absolute;
      @include padding(4);
      @include size(32px);
      @include minSize(32px);
      @extend .radius-xs;
      @extend .border-0;
      background-color: $black_sh1 !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238B8B90'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      background-size: 18px 18px !important;
      @extend .spring;

      &:hover,
      &:active,
      &:focus {
        color: $white;
        background-color: lighten($black_sh1, 2%) !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      }

      &:active {
        @include transform(scale(0.95) !important);
        * {
          @include transform(scale(0.95) !important);
        }
      }
    }
  }

  .modal-body {
    @include padding(0);

    // .left-sidebar-search {
    //   .icon-search {
    //     left: 12px;
    //   }
    //   .form-control {
    //     padding-left: 2.5rem;
    //   }
    // }
  }

  @media (max-width: 991px) {
    margin-left: auto !important;
    margin-right: auto !important;

    .search-close {
      display: none !important;
    }
  }
}

.search-result {
  height: 100vh;
  @include padding(16 0 20 20);
  max-height: calc(100vh - 205px);

  &-scroller {
    overflow-y: auto;
    overflow-x: hidden;
    @include padding(16 20 20 0);
    max-height: calc(100vh - 205px);
    overscroll-behavior-y: none !important;

    @supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee))
      and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
      @include padding-right(18);
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.675rem;
    gap: 0.675rem;
    position: relative;
    @include padding-bottom(10);

    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  &-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    background-color: $primary;
    @extend .radius-xs;

    .thumb {
      @include minSize(100%);
      @include maxSize(100%);
      box-sizing: border-box;
      display: block;
      overflow: hidden;
      width: initial;
      height: initial;
      background: none;
      inset: 0;
      opacity: 1;
      margin: 0;
      padding: 0;
      border: 0 none;
      position: absolute;
      transition-duration: 200ms;
      @include transition(all 0.3s ease-in-out);

      img {
        inset: 0;
        padding: 0;
        margin: auto;
        border: 0 none;
        display: block;
        position: absolute;
        box-sizing: border-box;
        @include size(100%);
        @include minSize(100%);
        @include maxSize(100%);
        @include object-fit(contain);
      }
    }
  }

  &-item {
    height: 0;
    width: 100%;
    position: relative;
    padding-bottom: 140%;
    @include transition(all 0.3s ease-in-out);
    @include margin-bottom(0);

    &:hover,
    &:focus,
    &:active {
      .thumb {
        @include transform(translateY(-0.75rem));
      }
    }
  }
}

.fake-link {
  inset: 0;
  position: absolute;
  @extend .radius-xs;
  @extend .u-flexed;
  @extend .u-align-start;
  @extend .u-justify-center;
  @include size(100%);
}

.modal-data-more {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 9.5rem;
  min-height: 5.5rem;
  padding-top: 4rem;
  cursor: pointer;
  position: absolute;
  @include padding-bottom(16);
  @extend .u-flexed;
  @extend .u-align-end;
  @extend .u-justify-center;
  @extend .primary-gradient;

  .show-more {
    color: $white;
    font-size: 14px;
    @extend .normalFont;
    @extend .fw-medium;
  }
}

.modal-search-filter {
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);

  &-left,
  &-right {
    width: 50%;
    padding: 0 6px;
    flex-shrink: 0;
    flex: 1;
  }

  &-wrap {
    height: 44px;
    @extend .w-full;
    @include padding(0);
    line-height: 44px;
    @extend .u-flexed;
    @extend .u-align-center;

    label {
      @extend .fs-14;
      @extend .fw-medium;
      @extend .normalFont;
      color: $lightergrey;
      @include padding-right(8);
    }

    .dropdown {
      width: 100%;
      height: 44px;
      max-height: 44px;
      @extend .u-flexed;
      @extend .u-align-center;
      background-color: $border2;
      @extend .radius-xs;

      .dropdown-toggle {
        width: 100%;
        height: 44px;
        max-height: 44px;
        @include padding(10 16);
        text-align: left;
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        border-radius: 0;
        @extend .fs-14;
        @extend .fw-medium;
        @extend .normalFont;
        color: $success;

        &::after {
          top: calc(50% - 8px);
          right: 1rem;
          @include size(16px);
          position: absolute;
          transform: none !important;
        }
      }

      &.show {
        border-radius: 0.25rem 0.25rem 0 0 !important;
      }
    }
  }
}

.modal-table {
  @include padding(10);
  border: 1px solid rgba($white, 0.05);
  @extend .radius-sm;

  .table {
    margin-bottom: 0;

    tr {
      td {
        color: $tertiary;
        font-size: 15px;
        line-height: 1.25;
        @include padding(5);

        &:last-of-type {
          color: $white;
          text-align: right;
        }
      }
    }
  }
}

.custom-modal-header {
  @extend .u-inline-flexed;
  min-height: 32px;
  @include padding-top(6);
  @extend .fs-18;
  line-height: 20px;
}

.modal-open {
  overflow: hidden !important;
}

.border-box {
  @include padding(16);
  @extend .radius-xs;
  border: 1px dashed rgba($white, 0.2);
  @extend .u-flexed;
  @extend .u-align-center;
  @include linear-gradient((0deg, $border2 0%, $border2 100%), $white);

  .max-130px {
    width: 100%;
    max-width: 130px;
  }

  .deposit-inner {
    @include padding-left(12);

    @include responsive(sm) {
      @include respond-to(md) {
        @include padding-right(12);

        @include respond-to(lg) {
          @include padding-right(12);
        }
      }
    }

    h5 {
      @include margin-top(0);
      @include margin-bottom(6);
    }
  }

  @media (max-width: 767px) {
    @include flex-direction(column, vertical !important);

    .max-130px {
      max-width: 100%;
      @include margin-top(12);
    }
  }

  .btn-support {
    height: 34px;
    @extend .fs-12;
    width: 100%;
    border: 0 none;
    text-align: center;
    @include padding(5 12);
    @extend .fw-semibold;
    @extend .normalFont;
    @extend .radius-xs;
    @include linear-gradient(
      (0deg, rgba($white, 0.05) 0%, rgba($white, 0.05) 100%),
      $border2
    );
  }

  &-v2 {
    @include padding(12);
    border: 1px solid rgba($white, 0.2);
    @include linear-gradient(
      (0deg, rgba($border2, 0) 0%, rgba($border2, 0) 100%),
      rgba($border2, 0)
    );
  }
}

.btn-2fa {
  border: 0 none;
  @extend .fs-14;
  @extend .fw-bold;
  @extend .primaryFont;
  @extend .radius-xs;
  @include linear-gradient(
    (0deg, rgba($white, 0.05) 0%, rgba($white, 0.05) 100%),
    $border2
  );

  &.btn-outline {
    height: 48px;
    color: $white;
    @extend .fs-14;
    @extend .radius-xs;
    @extend .fw-bold;
    border: 1px solid $dropdown;
    background-color: transparent;
    @include linear-gradient(
      (0deg, rgba($white, 0) 0%, rgba($white, 0) 100%),
      rgba($white, 0)
    );
    @include padding(8 24);

    &:hover,
    &:focus,
    &:active {
      color: $hoverColor;
      border: 1px solid rgba($hoverColor, 0.2) !important;
      background-color: rgba($hoverColor, 0.2) !important;
    }
  }
}
.btn-2fa,
.btn-support {
  &:hover,
  &:focus,
  &:active {
    color: $hoverColor;
    background-color: rgba($hoverColor, 0.2) !important;
  }
}

.sub-drop {
  top: auto;
  @extend .w-full;
  @extend .radius-xs;
  @include margin-top(6);
  @include box-shadow(0 0 5px 2px rgba($black, 0.1));
  background-color: $border2;
}

.withdrawal-form {
  @extend .w-full;

  .form-label {
    @extend .fs-14;
    @extend .fw-semibold;
    @extend .normalFont;
    @extend .u-flexed;
    @extend .u-align-center;
  }

  .dropdown {
    &.network-block,
    &.currency-block {
      .btn {
        height: 40px;
        @extend .w-full;
        @extend .fs-14;
        max-height: 40px;
        @include padding(0 12);
        @extend .u-flexed;
        @extend .u-align-center;
        border: 1px solid $border2;
        justify-content: space-between;
        @include linear-gradient((0deg, $border2 0%, $border2 100%), $white);

        &:hover,
        &:focus,
        &:active {
          border-color: rgba($white, 0.2);
          @include linear-gradient((0deg, $border2 0%, $border2 100%), $white);
        }

        > div {
          @extend .u-flexed;
          @extend .u-align-center;
        }
      }
      .number {
        @extend .fw-bold;
      }
    }
  }

  .input-group,
  .dropdown {
    &.network-block,
    &.currency-block {
      @extend .w-full;

      &.show,
      &.is-selected {
        .btn {
          border-color: $hoverColor;

          &::after {
            @include transform(rotate(0deg));
          }
        }
      }
    }

    &.currency-block {
      .currency-dropdown {
        @extend .sub-drop;

        &-body-content {
          height: 100%;
          max-height: 220px;

          .sidebar-menu-item {
            .sidebar-menu-link {
              padding: 0.375rem 0.75rem;

              .digit,
              .coin-name {
                color: $white;
              }

              &:hover,
              &:focus,
              &:active {
                color: $success;
                background-color: rgba($success, 0.2) !important;

                .digit,
                .coin-name {
                  color: $success;
                }
              }
            }
          }
        }
      }
    }

    .input-group,
    &.network-block {
      .dropdown-menu {
        @extend .sub-drop;

        &-wrap {
          max-height: 220px;
          overflow-y: auto;

          .dropdown-item {
            height: 44px;
            padding: 0.375rem 0.75rem;
            @extend .fs-14;
            color: $white;
            @extend .primaryFont;
            @extend .fw-bold;

            &:hover,
            &:focus,
            &:active {
              color: $success;
              background-color: rgba($success, 0.2) !important;
            }
          }
        }
      }

      &-big {
        height: 3rem;
        .btn-group {
          height: 3rem;
          margin: 0 auto 2rem;
        }
        .btn {
          height: 3rem;
          min-height: 3rem;
          max-height: 3rem;
          @include padding(12 16);
        }
      }
      // .btn {
      //   border-top-left-radius: 0 !important;
      //   border-bottom-left-radius: 0 !important;
      // }
    }
  }

  .form-control:not([name="search"]) {
    height: 40px;
    color: $white;
    border: 1px solid $border2;
    @extend .w-full;
    @extend .fs-14;
    @include padding(0 12);
    outline: none !important;
    box-shadow: none !important;
    background-color: $border2;
    @extend .fw-semibold;
    @extend .normalFont;
    @extend .radius-xs;

    &::placeholder {
      color: rgba($white, 0.5);
    }

    &:hover {
      border-color: rgba($white, 0.2);
    }
    &:focus {
      border-color: $success;
    }
  }

  .top-1 {
    top: 0.75rem !important;
  }
  .left-1 {
    left: 0.75rem !important;
  }

  .badge {
    &.currency {
      @include size(16px);
      @include padding(0);
      @extend .radius-xs;
      @extend .vc;
      @extend .u-inline-flexed;
    }

    &.bg-info {
      background-color: $info_sh2 !important;
    }
  }

  .with-icon {
    .form-control {
      @include padding-left(40);
      @include padding-right(70);

      &.no-pl {
        @include padding-left(12);
        font-family: $normalFont !important;
        @extend .fw-semibold;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px !important;
      }
    }

    .badge {
      height: 24px;
      @extend .fs-14;
      @extend .radius-xs;
      @extend .fw-bold;
      @extend .primaryFont;

      &.bg-success {
        color: $success;
        text-shadow: 0px 0px 2px rgba($success, 0.4);
        background: rgba($success, 0.2) !important;
      }

      &.top-1 {
        top: 0.5rem !important;
      }
    }
  }

  .input-group {
    flex-wrap: nowrap !important;

    .btn {
      height: 40px;
      border: 0 none;
      @extend .fs-14;
      max-height: 40px;
      @include padding(0 12);
      @extend .u-flexed;
      @extend .u-align-center;
      justify-content: space-between;
      background-color: $black_sh8 !important;

      &.min-100px {
        text-align: center;
        justify-content: center !important;
        @extend .normalFont;
        @extend .fw-semibold;
      }
    }

    &.network-block {
      .dropdown-menu {
        min-width: 100px;
        max-width: 130px;
      }
      .form-control {
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
          .valid-feedback
        ):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: 0 !important;
      }
    }
  }

  @media (max-width: 767px) {
    .mobile-col-8 {
      width: 66.66666667% !important;
      max-width: 66.66666667% !important;
      flex: 0 0 66.66666667% !important;
      flex-shrink: 0 !important;
    }
    .mobile-col-4 {
      width: 33.33333333% !important;
      max-width: 33.33333333% !important;
      flex: 0 0 33.33333333% !important;
      flex-shrink: 0 !important;
    }

    .mobile-col-8 {
      width: 66.66666667% !important;
      max-width: 66.66666667% !important;
      flex: 0 0 66.66666667% !important;
      flex-shrink: 0 !important;
    }
  }

  .form-switch {
    margin: 0;
    padding: 0;

    .form-check-input {
      @extend .form-check-input-big;
    }
  }

  &-action {
    .btn {
      width: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      @extend .radius-xs;
    }
  }
  .wallet-address-block {
    .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .btn-copy {
      height: 40px;
      border: 0 none;
      min-width: 65px;
      padding: 0rem 1rem;
      position: relative;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      background-color: #07070a !important;
    }
  }
}

/* End Modal Style */
.login-modal {
  .modal-content {
    @include box-shadow(0 2px 20px 10px rgba($black, 0.25) !important);
  }

  .btn-close {
    @extend .z-9;
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238B8B90'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
    @extend .spring;

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231ed760'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
    }
  }
}
.modal {
  &-autoheight {
    .modal-content {
      height: 760px;
      overflow: hidden;
      border-radius: 12px;
      max-height: calc(100vh - 25px);

      .modal-body {
        height: 100%;
        max-height: 780px;
      }
    }
  }

  &-backdrop {
    &.show {
      @include opacity(0.4);
    }
  }
}

@media (max-width: 767px) {
  .modal-withdraw {
    .col-right {
      .nav-link {
        height: 40px !important;
        padding: 0.625rem 0.563rem !important;
        font-size: 0.875rem !important;
        line-height: 20px !important;
      }
    }
  }

  .sm\:w-100 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .sm\:mt-16 {
    margin-top: 1rem !important;
  }
}

@media (max-width: 991px) {
  .md\:w-100 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .md\:flex-column {
    @include flex-direction(column, vertical);
  }
}

.logout-modal {
  &-wrap {
    @include padding(20);
  }

  &-header {
    min-height: 32px;
    @extend .u-flexed;
    @extend .u-align-center;
  }

  &-content {
    @include padding(24 0);
  }
}

// @media (min-width: 768px) {
//   .modal-withdraw {
//     .modal-content {
//       min-height: 566px;
//     }
//   }
// }

// Mobile Search
.mobile-search {
  .search-result {
    &-wrap {
      .thumb {
        @extend .o-hidden;
        @extend .radius-xs;

        img {
          @extend .radius-xs;
          @include object-fit(cover);
        }
      }
    }

    .game-card-block {
      @media (max-width: 480px) {
        gap: 20px 6px;
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (min-width: 481px) and (max-width: 767px) {
        gap: 20px 8px;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

// KYC Modal
.modal-kyc {
  &.modal-dialog {
    width: 100%;
    max-width: var(--bs-modal-kyc-width);

    @media (max-width: 767px) {
      width: auto;
    }

    .modal-content {
      overflow: hidden;
      @include border-radius(8px);

      @media (min-width: 768px) {
        height: 730px;
      }

      @media (max-width: 767px) {
        min-height: 730px;

        .modal-body,
        .col-forms,
        .form-horizontal-block {
          min-height: calc(730px - 72px);
        }
      }

      &-wrapper {
        height: 100%;
        @include flexbox;
        @include flex-direction(column, vertical);
      }
    }
  }
  .modal-header {
    .btn-close {
      position: absolute;
    }
  }

  .form-horizontal {
    &-top,
    &-bottom {
      padding: 1.25rem;
      background-color: transparent;
    }
    &-top {
      padding-top: 0;

      &-scroll {
        max-height: 570px;
      }
    }
  }
}

// Vault Modal
.modal-vault {
  &.modal-dialog {
    width: 100%;
    max-width: var(--bs-modal-vault-width);

    @media (max-width: 767px) {
      width: auto;
    }

    .modal-content {
      // overflow: hidden;
      @include border-radius(8px);

      @media (min-width: 768px) {
        min-height: 375px;
      }

      @media (max-width: 767px) {
        min-height: 375px;

        .modal-body,
        .col-forms,
        .form-horizontal-block {
          min-height: calc(375px - 65px);
        }
      }

      &-wrapper {
        height: 100%;
        min-height: 375px;
        @include flexbox;
        @include flex-direction(column, vertical);
      }
    }
  }
  .modal-header {
    .btn-close {
      position: absolute;
    }
  }

  .moday-body {
    &-wrap {
      padding: 0;
    }
  }

  .form-horizontal {
    &-top,
    &-bottom {
      padding: 1.25rem;
      background-color: transparent;
    }
    &-top {
      padding-top: 0;

      &-scroll {
        max-height: 375px;
      }
    }
  }
  .page-tabs {
    .nav-tabs {
      margin: 0 auto;
      background: linear-gradient(0deg, $border2 0%, $border2 100%),
        linear-gradient(0deg, $blue_sh10 0%, $blue_sh10 100%), $primary;
      @extend .radius-sm;
    }
  }

  // .tab-pane {
  //   > div {
  //     animation: 0.2s ease-out 0s 1 normal both running animation-tY10;
  //     animation-delay: 0s;
  //   }
  // }
}

@media (max-width: 767px) {
  .modal-withdraw-new {
    .modal-content-wrapper {
      .modal-body-top {
        &.is-height-auto,
        &.is-2FA-enabled {
          height: 498px;
          justify-content: flex-start !important;
        }
      }

      .form-label {
        height: 14px;

        > span {
          top: 2px;
          position: relative;
        }
      }
      .qr-code-block {
        min-height: 214px;

        .qr-code {
          margin-top: 2.5rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-withdraw-new {
    .modal-content,
    .modal-content-wrapper {
      .modal-body-top {
        height: 708px;

        &.is-height-auto,
        &.is-2FA-enabled {
          height: 478px;
          justify-content: flex-start !important;
        }
      }

      .form-label {
        height: 14px;

        > span {
          top: 2px;
          position: relative;
        }
      }
    }
  }
}

.modal-forget {
  .forget-bottom-header,
  .form-horizontal-top {
    padding: 1.25rem !important;

    &.pt-0 {
      padding-top: 0 !important;
    }
    &.p-0 {
      padding: 0 !important;
    }
  }
  .forget-bottom-header {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .form-horizontal-bottom {
    padding: 1.25rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
}

.kyc-modal-forms {
  .form-horizontal {
    .error-message {
      display: none !important;
    }
  }
}
